import { getRandomUUID } from '@/utils/functional';
import { useEffect, useReducer, useState } from 'react';
import { maybe } from './utils/functional';
import { FETCH_METHODS, getSummaryDataAsync, removeAgg } from './utils/tableau';

import type { Column, Parameter, Worksheet } from '@tableau/extensions-api-types';
import { ColumnState } from 'ag-grid-community';
import type { FilterAction, FilterClearType, GridAction } from './grid/actions';
import type { GridState } from './grid/interface';
import { getField } from './grid/mapping/utils';
import type { TooltipType as Tooltip } from './pages/tooltip/TooltipConfig';
import { Calculation } from './types/mapping';
import { ColumnConfig, ConfigMap, ContinuousColorConfig, ExportTypes, Theme, ThemeConfig } from './types/settings';
import { WriteBackExtreme } from './types/writeback';
import { deleteColumn } from '@/writeback/api';
import Cache from './utils/cache';

const cache = Cache.getInstance();
export type VerticalAlignment = 'Top' | 'Center' | 'Bottom';

export interface Settings {
  doNotStorePivotOrder: CheckedState | undefined;
  datasheet: Worksheet;
  useParameterForDatasheet: boolean;
  datasheetParameter: string;

  hideNewColumns: boolean;
  showCompactMode: boolean;
  autoRowHeight: boolean;
  showGroupBar: boolean;
  rowMultiSelectWithClick: boolean;
  rowMultiSelectEnabled: boolean;
  showSideBar: boolean;
  showColumnsPanel: boolean;
  groupColumnsPanel: boolean;
  showFiltersPanel: boolean;
  showFormulasPanel: boolean;
  showWbePanel: boolean;
  enableAdvancedFilter: boolean;
  includeHiddenColumnsInAdvancedFilter: boolean;
  showColumnMenu: boolean;
  showFilterBar: boolean;
  showStatusBar: boolean;
  showGroupIncludeFooter: boolean;
  showGroupIncludeTotalFooter: boolean;
  showGroupTotalAtTop: boolean;
  showSubGroupTotalAtTop: boolean;
  stickyGroupTotal: boolean;
  enableRtl: boolean;
  enableUnSortIcon: boolean;
  enablePagination: boolean;
  paginationPageSize: number;
  rowHeight: number;
  showGroupMultiColumn: boolean;
  showOpenedGroup: boolean;
  suppressGroupRowsSticky: boolean;
  showCheckbox: boolean;
  showPivotHeader: boolean;
  showCustomNotification: boolean;
  groupHideOpenParents: boolean;
  groupUseUnbalancedGroups: boolean;
  groupSuppressCount: boolean;
  enablePopUp: boolean;
  enableAutoAggregation: boolean;
  supressIndexColumn: boolean;
  showPopupOnData: boolean;
  enablePivotRowTotals: boolean;
  shouldFormatExport: boolean;
  exportLogging: boolean;
  copyLogging: boolean;
  userWorksheet: string;
  userColumn: string;
  getUsernameAsync: () => Promise<string>;
  showAggFuncInHeader: boolean;
  groupAggFiltering: boolean;
  charting: boolean;
  onlyNativeValues: boolean;

  enableEventFilterChanged: boolean;
  enableEventMarkSelectionChanged: boolean;
  enableEventParameterChanged: boolean;
  enableMultiLayoutsWithParameter: boolean;

  floatingExportButton: boolean;
  floatingExportButtonsType: ExportTypes[];
  exportIncludeImages: boolean;
  exportOddRowStyling: boolean;
  exportIncludeHeader: boolean;
  exportColumnGroups: boolean;
  exportRowGroups: boolean;
  exportVisibleColumns: boolean;
  exportAllButLastOne: boolean;
  exportFilteredColumns: boolean;
  exportTopNRows: number;

  themeConfig: ThemeConfig;
  useTableauFont: boolean;
  useAccessibilityMode: boolean;

  userCanChangeWidth: boolean;
  userCanSortColumns: boolean;
  userCanOrderColumns: boolean;
  userCanCopy: boolean;
  userCanAnonymize: boolean;
  userCanExport: boolean;
  CSVExport: boolean;
  ExcelExport: boolean;
  PDFExport: boolean;
  ExcelFileName: string | undefined;
  CSVFileName: string | undefined;

  autoExpandGroups: boolean;
  enableUrlActionsforGroupedValues: boolean;
  expandAllButLastNGroups: number;
  pivotDateFormat: string;
  customNotificationHeader: string;
  customNotificationText: string;

  pivotHeaderIsDate: boolean;

  fetchMethod: number;

  stateParameter: string | undefined;
  rowColorColumn: string | undefined;
  theme?: Theme;
  popUpWorksheets: any[];
  secondaryEventFilterWorksheets: any[];
  excludeEventParameters: any[];
  multiLayoutsParameters: any[];
  unbalancedGroupColumn: string | undefined;
  groupUnbalancedGroupValue: string | null;

  columnConfig: ConfigMap;
  columnGroupConfig: { [key: string]: ColumnGroupConfig | undefined };
  calculatedColumns: Calculation[];
  gridState: GridState;
  actions: GridAction[];

  useTableauTooltip: boolean;
  tooltips: Tooltip[];
  tooltipShowDelay: number;
  tooltipHideDelay: number;
  tooltipMouseTrack: boolean;
  tooltipInteraction: boolean;
  tooltipPadding: number;
  tooltipBorderWidth: number;
  tooltipBorderRadius: number;
  tooltipBackgroundColor: string;
  tooltipFontColor: string;
  tooltipBorderColor: string;
  verticalAlignment?: VerticalAlignment;

  writebackextreme: WriteBackExtreme;

  // Deprecated properties
  columnState?: any[]; // DEPRECATED. use `gridState` instead.
  targetSheets?: string[]; // DEPRECATED. use `actions` instead.
  actionKeyColumn?: string; // DEPRECATED. use `actions` instead.
  afterClearAction?: FilterClearType; // DEPRECATED. use `actions` instead.
}

type StringifiedSettings = Record<keyof Settings, string>;

export interface TableauColumn extends Column {
  fieldNameWithAgg: string;
}
export interface DataTable {
  readonly columns: Array<TableauColumn>;
  readonly data: Array<any>;
}

interface InitialState {
  summaryData: DataTable;
  parameters: Parameter[];
  loading: boolean;
  error: boolean;
}

// TODO: this should go to themeconfig type
export interface State extends Settings, InitialState {
  foregroundColor: string | undefined;
}

export interface ColumnGroupConfig {
  columnGroupId: string;
  isMultiLevelGroup: boolean;
  ColumnGroupBackgroundColor: string;
  ColumnGroupTextColor: string;
}

let tableau = window.tableau;
let extension = tableau.extensions;

let _shouldIgnoreSettingsChangedEvent = false;
export function ignoreNextSettingsChangedEvent() {
  _shouldIgnoreSettingsChangedEvent = true;
}

export function shouldIgnoreSettingsChangedEvent() {
  //if the lastSaved setting has origin dialog, we should not ignore the event
  if (extension.settings.get('origin') === 'dialog') {
    return false;
  } else {
    try {
      return _shouldIgnoreSettingsChangedEvent;
    } finally {
      _shouldIgnoreSettingsChangedEvent = false;
    }
  }
}

const parse: <T>(value: string | null | undefined, defaultTo?: T) => NonNullable<T> = maybe(JSON.parse);

let globalSettings: Settings | undefined;

export function parseSettings({ strict = true }: { strict?: boolean } = {}): Settings | undefined {
  const isVizExtension: boolean = !!extension.worksheetContent;
  let extensionSettings: StringifiedSettings = extension.settings.getAll() as StringifiedSettings;
  if (strict && extensionSettings.datasheet === undefined && !isVizExtension) {
    // return false to signal that we are unconfigured
    return undefined;
  }

  const isAlreadyConfigured = !!extensionSettings.datasheet;

  // TODO: find out which string variables are not part of extensionSettings and not part of the Settings interface
  let settings: Partial<Settings> = extensionSettings;
  settings.useParameterForDatasheet = parse(extensionSettings.useParameterForDatasheet, false);

  let sheet;
  if (isVizExtension) {
    sheet = extension.worksheetContent?.worksheet;
  } else {
    const worksheets = extension.dashboardContent?.dashboard.worksheets;
    if (!worksheets) {
      if (strict) {
        throw new Error('No worksheets found');
      }
      return undefined;
    }

    if (settings.useParameterForDatasheet) {
      try {
        const currentDatasheetName = cache.get('currentDatasheetFromParameter');
        if (!currentDatasheetName) {
          console.error('No datasheet name found in parameter');
          throw new Error('No datasheet name found in parameter');
        }

        sheet = worksheets.find((sheet) => sheet.name === currentDatasheetName);
      } catch (error) {
        sheet = worksheets?.find((sheet) => sheet.name === extensionSettings.datasheet);
      }
    } else {
      sheet = worksheets?.find((sheet) => sheet.name === extensionSettings.datasheet);
    }

    if (!sheet) {
      if (strict) {
        throw new Error(
          `Could not find sheet ${
            settings.useParameterForDatasheet
              ? `from parameter "${extensionSettings.datasheetParameter}". Sheet name found in parameter: "${cache.get('currentDatasheetFromParameter')}"`
              : `"${extensionSettings.datasheet}"`
          }`
        );
      }
      sheet = worksheets[0];
    }
  }

  settings.datasheet = sheet;
  settings.datasheetParameter = extensionSettings.datasheetParameter ?? '';

  settings.hideNewColumns = parse(extensionSettings.hideNewColumns, false);
  settings.showCompactMode = parse(extensionSettings.showCompactMode, false);
  settings.useTableauFont = parse(extensionSettings.useTableauFont, false);
  settings.useAccessibilityMode = parse(extensionSettings.useAccessibilityMode, false);
  settings.autoRowHeight = parse(extensionSettings.autoRowHeight, false);
  settings.showGroupBar = parse(extensionSettings.showGroupBar, true);
  settings.rowMultiSelectWithClick = parse(extensionSettings.rowMultiSelectWithClick, false);
  settings.rowMultiSelectEnabled = parse(extensionSettings.rowMultiSelectEnabled, true);
  settings.showSideBar = parse(extensionSettings.showSideBar, true);
  settings.groupColumnsPanel = parse(extensionSettings.groupColumnsPanel, false);
  settings.showColumnsPanel = parse(extensionSettings.showColumnsPanel, true);
  settings.showFiltersPanel = parse(extensionSettings.showFiltersPanel, true);
  settings.showFormulasPanel = parse(extensionSettings.showFormulasPanel, true);

  settings.enableAdvancedFilter = parse(extensionSettings.enableAdvancedFilter, false);
  settings.includeHiddenColumnsInAdvancedFilter = parse(extensionSettings.includeHiddenColumnsInAdvancedFilter, true);
  settings.showColumnMenu = parse(extensionSettings.showColumnMenu, true);
  settings.showFilterBar = parse(extensionSettings.showFilterBar, true);
  settings.showStatusBar = parse(extensionSettings.showStatusBar, true);
  settings.showGroupIncludeFooter = parse(extensionSettings.showGroupIncludeFooter, true);
  settings.showGroupIncludeTotalFooter = parse(extensionSettings.showGroupIncludeTotalFooter, true);
  settings.showGroupTotalAtTop = parse(extensionSettings.showGroupTotalAtTop, false);
  settings.showSubGroupTotalAtTop = parse(extensionSettings.showSubGroupTotalAtTop, false);
  settings.stickyGroupTotal = parse(extensionSettings.stickyGroupTotal, false);
  settings.enableRtl = parse(extensionSettings.enableRtl, false);
  settings.enableUnSortIcon = parse(extensionSettings.enableUnSortIcon, false);
  settings.enablePagination = parse(extensionSettings.enablePagination, false);
  settings.paginationPageSize = parse(extensionSettings.paginationPageSize, null);
  settings.showGroupMultiColumn = parse(extensionSettings.showGroupMultiColumn, false);
  settings.showOpenedGroup = parse(extensionSettings.showOpenedGroup, false);
  settings.suppressGroupRowsSticky = parse(extensionSettings.suppressGroupRowsSticky, false);
  settings.showCheckbox = parse(extensionSettings.showCheckbox, true);
  settings.showPivotHeader = parse(extensionSettings.showPivotHeader, true);
  settings.showCustomNotification = parse(extensionSettings.showCustomNotification, false);
  settings.groupHideOpenParents = parse(extensionSettings.groupHideOpenParents, false);
  settings.groupSuppressCount = parse(extensionSettings.groupSuppressCount, false);
  settings.enablePivotRowTotals = parse(extensionSettings.enablePivotRowTotals, false);
  settings.rowHeight = parse(extensionSettings.rowHeight, null);

  settings.enablePopUp = parse(extensionSettings.enablePopUp, false);
  settings.enableAutoAggregation = parse(extensionSettings.enableAutoAggregation, false);
  settings.supressIndexColumn = parse(extensionSettings.supressIndexColumn, false);
  settings.showPopupOnData = parse(extensionSettings.showPopupOnData, false);
  settings.popUpWorksheets = parse(extensionSettings.popUpWorksheets, []);
  settings.secondaryEventFilterWorksheets = parse(extensionSettings.secondaryEventFilterWorksheets, []);
  settings.excludeEventParameters = parse(extensionSettings.excludeEventParameters, []);
  settings.multiLayoutsParameters = parse(extensionSettings.multiLayoutsParameters, []);
  settings.unbalancedGroupColumn = parse(extensionSettings.unbalancedGroupColumn, '');

  settings.shouldFormatExport = parse(extensionSettings.shouldFormatExport, true);
  settings.exportLogging = parse(extensionSettings.exportLogging, false);
  settings.copyLogging = parse(extensionSettings.copyLogging, false);
  settings.userColumn = parse(extensionSettings.userColumn, '');
  settings.userWorksheet = parse(extensionSettings.userWorksheet, '');
  settings.showAggFuncInHeader = parse(extensionSettings.showAggFuncInHeader, true);
  settings.groupAggFiltering = parse(extensionSettings.groupAggFiltering, false);
  settings.charting = parse(extensionSettings.charting, false);
  settings.onlyNativeValues = parse(extensionSettings.onlyNativeValues, true);
  settings.fetchMethod = parse(extensionSettings.fetchMethod, FETCH_METHODS.All_at_once);
  settings.enableEventFilterChanged = parse(extensionSettings.enableEventFilterChanged, true);
  settings.enableEventMarkSelectionChanged = parse(extensionSettings.enableEventMarkSelectionChanged, true);
  settings.enableEventParameterChanged = parse(extensionSettings.enableEventParameterChanged, true);
  settings.enableMultiLayoutsWithParameter = parse(extensionSettings.enableMultiLayoutsWithParameter, false);
  settings.stateParameter = extensionSettings.stateParameter;

  settings.themeConfig = parse(extensionSettings.themeConfig, {});

  settings.floatingExportButton = parse(extensionSettings.floatingExportButton, false);
  settings.floatingExportButtonsType = parse(extensionSettings.floatingExportButtonsType, []);
  settings.exportOddRowStyling = parse(extensionSettings.exportOddRowStyling, true);
  settings.groupUseUnbalancedGroups = parse(extensionSettings.groupUseUnbalancedGroups, false);
  settings.exportIncludeHeader = parse(extensionSettings.exportIncludeHeader, true);
  settings.exportColumnGroups = parse(extensionSettings.exportColumnGroups, true);
  settings.exportRowGroups = parse(extensionSettings.exportRowGroups, true);
  settings.exportVisibleColumns = parse(extensionSettings.exportVisibleColumns, true);
  settings.exportAllButLastOne = parse(extensionSettings.exportAllButLastOne, false);
  settings.exportFilteredColumns = parse(extensionSettings.exportFilteredColumns, true);
  settings.exportIncludeImages = parse(extensionSettings.exportIncludeImages, false);

  settings.userCanChangeWidth = parse(extensionSettings.userCanChangeWidth, true);
  settings.userCanSortColumns = parse(extensionSettings.userCanSortColumns, true);
  settings.userCanOrderColumns = parse(extensionSettings.userCanOrderColumns, true);
  settings.userCanCopy = parse(extensionSettings.userCanCopy, true);
  settings.userCanAnonymize = parse(extensionSettings.userCanAnonymize, true);
  settings.userCanExport = parse(extensionSettings.userCanExport, true);
  settings.CSVExport = parse(extensionSettings.CSVExport, true);
  settings.ExcelExport = parse(extensionSettings.ExcelExport, true);
  settings.PDFExport = parse(extensionSettings.PDFExport, true);

  settings.autoExpandGroups = parse(extensionSettings.autoExpandGroups, false);
  settings.enableUrlActionsforGroupedValues = parse(extensionSettings.enableUrlActionsforGroupedValues, true);
  settings.expandAllButLastNGroups = parse(extensionSettings.expandAllButLastNGroups, 0);
  settings.groupUnbalancedGroupValue = extensionSettings.groupUnbalancedGroupValue
    ? extensionSettings.groupUnbalancedGroupValue
    : null;
  settings.exportTopNRows = parse(extensionSettings.exportTopNRows, 0);
  settings.pivotDateFormat = extensionSettings.pivotDateFormat ? extensionSettings.pivotDateFormat : 'yyyy-MM';
  settings.pivotHeaderIsDate = parse(extensionSettings.pivotHeaderIsDate, false);
  settings.doNotStorePivotOrder = parse(extensionSettings.doNotStorePivotOrder, false);
  settings.customNotificationHeader = extensionSettings.customNotificationHeader
    ? extensionSettings.customNotificationHeader
    : '';
  settings.customNotificationText = extensionSettings.customNotificationText
    ? extensionSettings.customNotificationText
    : '';

  settings.calculatedColumns = parse(extensionSettings.calculatedColumns, []);
  settings.gridState = parse(extensionSettings.gridState, {});
  settings.actions = parse(extensionSettings.actions, []);

  settings.useTableauTooltip = parse(extensionSettings.useTableauTooltip, false);
  settings.tooltips = parse(extensionSettings.tooltips, []);
  settings.tooltipShowDelay = parse(extensionSettings.tooltipShowDelay, 0);
  settings.tooltipHideDelay = parse(extensionSettings.tooltipHideDelay, 20);
  settings.tooltipMouseTrack = parse(extensionSettings.tooltipMouseTrack, false);
  settings.tooltipInteraction = parse(extensionSettings.tooltipInteraction, false);
  settings.tooltipPadding = parse(extensionSettings.tooltipPadding, 6);
  settings.tooltipBorderWidth = parse(extensionSettings.tooltipBorderWidth, 1);
  settings.tooltipBorderRadius = parse(extensionSettings.tooltipBorderRadius, 1);
  settings.tooltipBackgroundColor = parse(extensionSettings.tooltipBackgroundColor, '#ffffff');
  settings.tooltipFontColor = parse(extensionSettings.tooltipFontColor, '#000000');
  settings.tooltipBorderColor = parse(extensionSettings.tooltipBorderColor, '#d4d4d4');

  settings.columnGroupConfig = parse(extensionSettings.columnGroupConfig, {});
  settings.verticalAlignment = (extensionSettings.verticalAlignment as VerticalAlignment) ?? 'Top';

  // showWbePanel should be true by default for new dashboards only
  settings.showWbePanel = parse(extensionSettings.showWbePanel, !isAlreadyConfigured);
  settings.writebackextreme = parse(extensionSettings.writebackextreme, defaultWriteBackExtreme);

  if (import.meta.env.VITE_PRODUCT === 'e') {
    settings.getUsernameAsync = async () => {
      const userColumnDefined = isVizExtension
        ? settings.userColumn
        : settings.userColumn && settings.userColumn !== '';
      // get the name from the user worksheet if we have a user column
      if (userColumnDefined) {
        const selectedWorksheet = isVizExtension
          ? extension.worksheetContent?.worksheet
          : tableau.extensions.dashboardContent?.dashboard.worksheets.find(
              (sheet) => sheet.name === settings.userWorksheet
            );
        if (selectedWorksheet) {
          const rawDatasheet = await selectedWorksheet.getSummaryDataAsync({
            maxRows: 1,
            ignoreAliases: true,
            ignoreSelection: true,
            columnsToIncludeById: [settings.userColumn ?? ''],
            includeDataValuesOption: tableau.IncludeDataValuesOption.OnlyNativeValues,
          });

          if (rawDatasheet && rawDatasheet.data.length > 0) {
            const {
              data: [[{ nativeValue: name }]],
            } = rawDatasheet;
            return name;
          }
        }
      }
    };
  }

  // there are a number of string parameters we are not parsing here

  /*
    BACKWARDS COMPATIBILITY
  */

  settings.columnConfig = parse<ConfigMap>(extensionSettings.columnConfig, {});
  let removedAggColConfig: { [key: string]: ColumnConfig } = {};

  for (let key of Object.keys(settings.columnConfig)) {
    // backwards compatibility for backgroundColor. Moving to color.backgroundColor
    const columnConfig = settings.columnConfig[key];
    if (columnConfig && columnConfig.hasOwnProperty('backgroundColor')) {
      columnConfig.color = columnConfig.color ?? {};
      // @ts-ignore - This is for backwards compatibility
      columnConfig.color.backgroundColor = columnConfig.backgroundColor;
      // @ts-ignore - This is for backwards compatibility
      delete columnConfig.backgroundColor;
    }

    // backwards compatibility for colorCellBackground. Ensuring colorCellBackground is always set.
    if (
      columnConfig &&
      (columnConfig.colorCellBackground === undefined || !columnConfig.hasOwnProperty('colorCellBackground')) &&
      (columnConfig.color?.lowerBound !== undefined || columnConfig.color?.upperBound !== undefined)
    ) {
      if (columnConfig.color?.lowerBound !== '' || columnConfig.color?.upperBound !== '') {
        columnConfig.colorCellBackground = true;
      }
    }

    if (columnConfig?.color && 'type' in columnConfig.color && columnConfig.color.type === 'continuous') {
      const colorConfig = columnConfig.color as ContinuousColorConfig;
      colorConfig.barchartUseAbsoluteValues = colorConfig.barchartUseAbsoluteValues ?? true;
    }

    removedAggColConfig[removeAgg(key)] = columnConfig;
  }
  settings.columnConfig = removedAggColConfig;

  // restructure columnState setting into gridState for backwards compatibility
  let columnState = parse<ColumnState[]>(extensionSettings.columnState);
  if (columnState && !settings.gridState.columnState) {
    settings.gridState.columnState = columnState;
  }

  // restructure actionKeyColumn, targetSheets and afterClearAction settings
  // into actions for backwards compatibility
  let targetSheets = parse<string[]>(extensionSettings.targetSheets, []);
  for (let sheet of targetSheets) {
    settings.actions.push({
      type: 'filter',
      source: settings.actionKeyColumn,
      target: sheet,
      afterClear: settings.afterClearAction,
    } as FilterAction);
  }
  globalSettings = settings as Settings;
  return settings as Settings;
}

export function getSettings() {
  return globalSettings;
}

let index = 1;

function newCalculation(): Calculation {
  return {
    index: `calc_${Date.now()}`,
    fieldName: `Calculation ${index++}`,
    calculation: '',
    //@ts-ignore can't seem to import the dailogStyle from the tableau
    //TODO: Figure out a fix
    dataType: 'float',
  };
}

const emptyDataTable: DataTable = {
  name: '',
  isSummaryData: true,
  totalRowCount: 0,
  isTotalRowCountLimited: false,
  columns: [],
  data: [],
};

const initialState: InitialState = {
  loading: true,
  // error is only used for handling exceptions during saving
  // this should just be handled by our consumers in .catch()
  error: false,
  summaryData: emptyDataTable,
  parameters: [],
};
function getInitialState(): Settings | InitialState {
  let settings = parseSettings({ strict: false });
  if (!settings) {
    return { ...initialState, loading: false };
  }
  return { ...initialState, ...settings };
}

function reducer(state: State, action: any): State {
  switch (action.type) {
    case 'setSettings':
      return { ...state, ...action.settings };
    case 'reset':
      return getInitialState() as State;

    case 'error':
      return { ...state, error: action.error };

    case 'setSheetData':
      return {
        ...state,
        summaryData: action.summaryData,
        parameters: action.parameters,
        loading: false,
      };

    case 'updateDatasheet':
      return {
        ...state,
        datasheet: action.sheet,
        targetSheets: [],
        actionKeyColumn: undefined,
      };

    case 'toggleUseParameterForDatasheet':
      return { ...state, useParameterForDatasheet: !state.useParameterForDatasheet };
    case 'setDatasheetParameter':
      return { ...state, datasheetParameter: action.datasheetParameter };

    case 'addCalculation':
      let newCalc = newCalculation();
      // @hack: this feels really bad, but it works so whatever...
      action.callback(newCalc.index);
      return {
        ...state,
        calculatedColumns: [...state.calculatedColumns, newCalc],
      };

    case 'updateCalculation': {
      let calculatedColumns = state.calculatedColumns.map((column) => {
        if (column.index === action.index) {
          return { ...column, ...action.change };
        }
        return column;
      });
      return { ...state, calculatedColumns };
    }

    case 'deleteCalculation': {
      let calculatedColumns = state.calculatedColumns.filter((column) => {
        return column.index !== action.index;
      });
      return { ...state, calculatedColumns };
    }

    case 'addAction':
      let newAction: GridAction = { type: 'filter', afterClear: 'clear-filter', dateFormat: 'yyyy-MM-DD' };
      return {
        ...state,
        actions: [...state.actions, newAction],
      };

    case 'updateAction': {
      let actions = state.actions.map((item, index) => {
        if (index === action.index) {
          return { ...item, ...action.change };
        }
        return item;
      });
      return { ...state, actions };
    }

    case 'deleteAction': {
      let actions = state.actions.filter((item, index) => {
        return index !== action.index;
      });
      return { ...state, actions };
    }
    case 'toggleTableauTooltip':
      return {
        ...state,
        useTableauTooltip: !state.useTableauTooltip,
      };
    case 'updateTooltipSettings':
      return {
        ...state,
        [action.setting]: action.value,
      };
    case 'toggleTooltipMouseTrack':
      return {
        ...state,
        tooltipMouseTrack: !state.tooltipMouseTrack,
      };
    case 'toggleTooltipInteraction':
      return {
        ...state,
        tooltipInteraction: !state.tooltipInteraction,
      };
    case 'addTooltip': {
      return {
        ...state,
        tooltips: [...state.tooltips, action.tooltip],
      };
    }
    case 'updateTooltip': {
      let tooltips = state.tooltips.map((item) => {
        if (item.id === action.id) {
          return { ...item, ...action.tooltip };
        }
        return item;
      });
      return { ...state, tooltips };
    }
    case 'deleteTooltip': {
      let tooltips = state.tooltips.filter((item) => {
        return item.id !== action.id;
      });
      return { ...state, tooltips };
    }
    case 'updateColumnConfig': {
      const wbeColumnConfig = state.writebackextreme.columnConfig
        ? state.writebackextreme.columnConfig[action.index]
        : null;
      if (wbeColumnConfig) {
        return {
          ...state,
          writebackextreme: {
            ...state.writebackextreme,
            columnConfig: {
              ...state.writebackextreme.columnConfig,
              [action.index]: {
                ...(state.writebackextreme.columnConfig[action.index] ?? {}),
                ...action.change,
              },
            },
          },
        };
      }

      return {
        ...state,
        columnConfig: {
          ...state.columnConfig,
          [action.index]: {
            ...state.columnConfig[action.index],
            ...action.change,
          },
        },
      };
    }

    case 'deleteWBEColumn': {
      deleteColumn(state.writebackextreme.url, action.token, state.writebackextreme.schemaID, action.key);
      delete state.writebackextreme.columnConfig[action.key];
      delete state.writebackextreme.columnProperties[action.key];
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          columnConfig: {
            ...state.writebackextreme.columnConfig,
          },
          columnProperties: {
            ...state.writebackextreme.columnProperties,
          },
        },
      };
    }

    case 'updateColumnConfigColor': {
      if (state.writebackextreme.columnConfig[action.index]) {
        const wbeColumnConfig = state.writebackextreme.columnConfig[action.index];
        return {
          ...state,
          writebackextreme: {
            ...state.writebackextreme,
            columnConfig: {
              ...state.writebackextreme.columnConfig,
              [action.index]: {
                ...wbeColumnConfig,
                color: {
                  ...wbeColumnConfig?.color,
                  ...action.change,
                },
              },
            },
          },
        };
      }

      return {
        ...state,
        columnConfig: {
          ...state.columnConfig,
          [action.index]: {
            ...state.columnConfig[action.index],
            color: {
              ...state.columnConfig?.[action.index]?.color,
              ...action.change,
            },
          },
        },
      };
    }
    case 'updateColumnGroupConfig':
      // If there is no unique column id, we generate a new one.
      const columnGroupId = action.index ?? `CGID-${getRandomUUID()}`;
      // If the name can be found but the id is different dont save/overwrite existing settings.
      if (
        !state ||
        (state.columnGroupConfig[action.groupName]?.columnGroupId &&
          state.columnGroupConfig[action.groupName]?.columnGroupId !== action.index)
      ) {
        return state;
      }
      return {
        ...state,
        columnGroupConfig: {
          ...state.columnGroupConfig,
          [action.groupName]: {
            columnGroupId: columnGroupId,
            ...state.columnGroupConfig[action.groupName],
            ...action.change,
          },
        },
      };
    case 'deleteColumnGroupConfig':
      let columnGroupConfig = state.columnGroupConfig;
      delete columnGroupConfig[action.index];
      return {
        ...state,
        columnGroupConfig,
      };

    case 'updateThemeConfig':
      return {
        ...state,
        themeConfig: {
          ...state.themeConfig,
          ...action.change,
        },
      };

    case 'deleteThemeConfigKey':
      let themeConfig = state.themeConfig;
      themeConfig[action.key] = null;
      return {
        ...state,
        themeConfig,
      };

    case 'setRowColorColumn':
      return { ...state, rowColorColumn: action.column };

    case 'setStateParameter':
      return { ...state, stateParameter: action.parameter };

    case 'setFetchMethod':
      return { ...state, fetchMethod: action.method };

    case 'toggleAutoExpandGroups':
      return { ...state, autoExpandGroups: !state.autoExpandGroups };

    case 'toggleEnableUrlActionsforGroupedValues':
      return {
        ...state,
        enableUrlActionsforGroupedValues: !state.enableUrlActionsforGroupedValues,
      };

    case 'setExpandAllButLastNGroups':
      return { ...state, expandAllButLastNGroups: action.n };

    case 'setCSVFileName':
      return { ...state, CSVFileName: action.name };
    case 'setExcelFileName':
      return { ...state, ExcelFileName: action.name };

    case 'setGroupUnbalancedGroupValue':
      return { ...state, groupUnbalancedGroupValue: action.n };

    case 'setExportTopNRows':
      return { ...state, exportTopNRows: action.n };
    case 'setUserWorksheet':
      return { ...state, userWorksheet: action.n };
    case 'setUserColumn':
      return { ...state, userColumn: action.n };
    case 'setRowHeight':
      return { ...state, rowHeight: action.n };

    case 'setPaginationPageSize':
      return { ...state, paginationPageSize: action.n };

    case 'setPivotDateFormat':
      return { ...state, pivotDateFormat: action.pivotDateFormat };

    case 'setPivotHeaderIsDate':
      return { ...state, pivotHeaderIsDate: action.pivotHeaderIsDate };
    case 'setCustomNotificationHeader':
      return {
        ...state,
        customNotificationHeader: action.customNotificationHeader,
      };
    case 'setCustomNotificationText':
      return {
        ...state,
        customNotificationText: action.customNotificationText,
      };
    case 'setTheme':
      return { ...state, theme: action.theme };

    case 'setPopUpWorksheets':
      return { ...state, popUpWorksheets: action.popUpWorksheets };

    case 'setSecondaryEventFilterWorksheets':
      return {
        ...state,
        secondaryEventFilterWorksheets: action.secondaryEventFilterWorksheets,
      };

    case 'setExcludeEventParameters':
      return {
        ...state,
        excludeEventParameters: action.excludeEventParameters,
      };
    case 'setMultiLayoutsParameters':
      return {
        ...state,
        multiLayoutsParameters: action.multiLayoutsParameters,
      };
    case 'setUnbalancedGroupColumn':
      return {
        ...state,
        unbalancedGroupColumn: action.unbalancedGroupColumn,
      };

    case 'charting':
      return { ...state, charting: !state.charting };
    case 'useTableauFont':
      return { ...state, useTableauFont: !state.useTableauFont };
    case 'toggleUseAccessibilityMode':
      return { ...state, useAccessibilityMode: !state.useAccessibilityMode };
    case 'onlyNativeValues':
      return { ...state, onlyNativeValues: !state.onlyNativeValues };

    case 'toggleHideNewColumns':
      return { ...state, hideNewColumns: !state.hideNewColumns };
    case 'toggleCompactMode':
      return { ...state, showCompactMode: !state.showCompactMode };
    case 'toggleAutoRowHeight':
      return { ...state, autoRowHeight: !state.autoRowHeight };
    case 'toggleGroupBar':
      return { ...state, showGroupBar: !state.showGroupBar };
    case 'toggleRowMultiSelectWithClick':
      return {
        ...state,
        rowMultiSelectWithClick: !state.rowMultiSelectWithClick,
      };
    case 'toggleRowMultiSelectEnabled':
      return {
        ...state,
        rowMultiSelectEnabled: !state.rowMultiSelectEnabled,
      };
    case 'toggleSupressIndexColumn':
      return { ...state, supressIndexColumn: !state.supressIndexColumn };
    case 'toggleSideBar':
      return { ...state, showSideBar: !state.showSideBar };
    case 'toggleColumnsPanel':
      return { ...state, showColumnsPanel: !state.showColumnsPanel };
    case 'toggleGroupColumnsPanel':
      return { ...state, groupColumnsPanel: !state.groupColumnsPanel };
    case 'toggleFiltersPanel':
      return { ...state, showFiltersPanel: !state.showFiltersPanel };
    case 'toggleFormulasPanel':
      return { ...state, showFormulasPanel: !state.showFormulasPanel };
    case 'toggleWbePanel':
      return { ...state, showWbePanel: !state.showWbePanel };
    case 'enableWbePanel':
      return { ...state, showWbePanel: true };
    case 'toggleAdvancedFilter':
      return { ...state, enableAdvancedFilter: !state.enableAdvancedFilter };
    case 'toggleIncludeHiddenColumnsInAdvancedFilter':
      return { ...state, includeHiddenColumnsInAdvancedFilter: !state.includeHiddenColumnsInAdvancedFilter };
    case 'toggleColumnMenu':
      return { ...state, showColumnMenu: !state.showColumnMenu };
    case 'toggleFilterBar':
      return { ...state, showFilterBar: !state.showFilterBar };
    case 'toggleStatusBar':
      return { ...state, showStatusBar: !state.showStatusBar };
    case 'toggleGroupIncludeFooter':
      return {
        ...state,
        showGroupIncludeFooter: !state.showGroupIncludeFooter,
      };
    case 'toggleGroupIncludeTotalFooter':
      return {
        ...state,
        showGroupIncludeTotalFooter: !state.showGroupIncludeTotalFooter,
      };
    case 'toggleGroupTotalAtTop':
      return {
        ...state,
        showGroupTotalAtTop: !state.showGroupTotalAtTop,
      };
    case 'toggleSubGroupTotalAtTop':
      return {
        ...state,
        showSubGroupTotalAtTop: !state.showSubGroupTotalAtTop,
      };
    case 'toggleStickyGroupTotal':
      return {
        ...state,
        stickyGroupTotal: !state.stickyGroupTotal,
      };
    case 'toggleEnableRtl':
      return { ...state, enableRtl: !state.enableRtl };
    case 'toggleEnableUnSortIcon':
      return { ...state, enableUnSortIcon: !state.enableUnSortIcon };
    case 'toggleEnablePagination':
      return { ...state, enablePagination: !state.enablePagination };
    case 'toggleGroupMultiColumn':
      return { ...state, showGroupMultiColumn: !state.showGroupMultiColumn };
    case 'toggleOpenedGroup':
      return { ...state, showOpenedGroup: !state.showOpenedGroup };
    case 'toggleGroupStickyRow':
      return { ...state, suppressGroupRowsSticky: !state.suppressGroupRowsSticky };
    case 'toggleCheckbox':
      return { ...state, showCheckbox: !state.showCheckbox };
    case 'togglePivotHeader':
      return { ...state, showPivotHeader: !state.showPivotHeader };
    case 'toggleShowCustomNotification':
      return {
        ...state,
        showCustomNotification: !state.showCustomNotification,
      };
    case 'toggleGroupHideOpenParents':
      return { ...state, groupHideOpenParents: !state.groupHideOpenParents };
    case 'toggleGroupSuppressCount':
      return { ...state, groupSuppressCount: !state.groupSuppressCount };
    case 'toggleEnablePopUp':
      return { ...state, enablePopUp: !state.enablePopUp };
    case 'toggleEnableAutoAggregation':
      return { ...state, enableAutoAggregation: !state.enableAutoAggregation };
    case 'toggleShowPopupOnData':
      return { ...state, showPopupOnData: !state.showPopupOnData };
    case 'togglePivotRowTotals':
      return { ...state, enablePivotRowTotals: !state.enablePivotRowTotals };
    case 'togglePivotHeaderIsDate':
      return { ...state, pivotHeaderIsDate: !state.pivotHeaderIsDate };
    case 'toggleDoNotStorePivotOrder':
      return { ...state, doNotStorePivotOrder: !state.doNotStorePivotOrder };
    case 'toggleFormatExport':
      return { ...state, shouldFormatExport: !state.shouldFormatExport };
    case 'toggleExportLogging':
      return { ...state, exportLogging: !state.exportLogging };
    case 'toggleExportIncludeImages':
      return { ...state, exportIncludeImages: !state.exportIncludeImages };
    case 'toggleCopyLogging':
      return { ...state, copyLogging: !state.copyLogging };
    case 'toggleShowAggFuncInHeader':
      return { ...state, showAggFuncInHeader: !state.showAggFuncInHeader };
    case 'toggleGroupAggFiltering':
      return { ...state, groupAggFiltering: !state.groupAggFiltering };
    case 'toggleFloatingExportButton':
      return { ...state, floatingExportButton: !state.floatingExportButton };
    case 'setFloatingExportButtonsType':
      return {
        ...state,
        floatingExportButtonsType: state.floatingExportButtonsType.includes(action.buttonType)
          ? state.floatingExportButtonsType.filter((item) => item !== action.buttonType)
          : [...state.floatingExportButtonsType, action.buttonType],
      };
    case 'toggleExportOddRowStyling':
      return { ...state, exportOddRowStyling: !state.exportOddRowStyling };
    case 'toggleGroupUseUnbalancedGroups':
      return {
        ...state,
        groupUseUnbalancedGroups: !state.groupUseUnbalancedGroups,
      };
    case 'toggleExportIncludeHeader':
      return { ...state, exportIncludeHeader: !state.exportIncludeHeader };
    case 'toggleExportColumnGroups':
      return { ...state, exportColumnGroups: !state.exportColumnGroups };
    case 'toggleExportRowGroups':
      return { ...state, exportRowGroups: !state.exportRowGroups };
    case 'toggleExportVisibleColumns':
      return { ...state, exportVisibleColumns: !state.exportVisibleColumns };
    case 'toggleExportAllButLastOne':
      return { ...state, exportAllButLastOne: !state.exportAllButLastOne };
    case 'toggleExportFilteredColumns':
      return { ...state, exportFilteredColumns: !state.exportFilteredColumns };
    case 'toggleEventFilterChanged':
      return {
        ...state,
        enableEventFilterChanged: !state.enableEventFilterChanged,
      };
    case 'toggleEventMarkSelectionChanged':
      return {
        ...state,
        enableEventMarkSelectionChanged: !state.enableEventMarkSelectionChanged,
      };
    case 'toggleEventParameterChanged':
      return {
        ...state,
        enableEventParameterChanged: !state.enableEventParameterChanged,
      };
    case 'toggleMultiLayoutsWithParameter':
      return {
        ...state,
        enableMultiLayoutsWithParameter: !state.enableMultiLayoutsWithParameter,
      };

    case 'toggleUserCanChangeWidth':
      return { ...state, userCanChangeWidth: !state.userCanChangeWidth };
    case 'toggleUserCanSortColumns':
      return { ...state, userCanSortColumns: !state.userCanSortColumns };
    case 'toggleUserCanOrderColumns':
      return { ...state, userCanOrderColumns: !state.userCanOrderColumns };
    case 'toggleUserCanCopy':
      return { ...state, userCanCopy: !state.userCanCopy };
    case 'toggleUserCanAnonymize':
      return { ...state, userCanAnonymize: !state.userCanAnonymize };
    case 'toggleUserCanExport':
      return { ...state, userCanExport: !state.userCanExport };
    case 'toggleCSVExport':
      return { ...state, CSVExport: !state.CSVExport };
    case 'toggleExcelExport':
      return { ...state, ExcelExport: !state.ExcelExport };
    case 'togglePDFExport':
      return { ...state, PDFExport: !state.PDFExport };
    case 'setVerticalAlignment':
      return { ...state, verticalAlignment: action.verticalAlignment };

    case 'resetWBE':
      return {
        ...state,
        writebackextreme: {
          ...defaultWriteBackExtreme,
        },
      };

    case 'setWriteBackExtreme':
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          ...action.payload,
        },
      };
    case 'resetWBESchema':
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          schemaID: '',
          schemaName: '',
          connectionID: '',
          primaryKeyColumn: '',
          primaryKeySlug: '',
          columnConfig: {},
          columnProperties: {},
        },
      };
    case 'setWBEComment':
      return {
        ...state,
        showWbePanel: true,
        writebackextreme: {
          ...state.writebackextreme,
          comments: {
            ...state.writebackextreme.comments,
            ...action.payload,
          },
        },
      };
    case 'resetWBEComment':
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          comments: {
            ...defaultWriteBackExtreme.comments,
            enabled: state.writebackextreme.comments.enabled,
            showIndicator: true,
          },
        },
      };

    case 'setWBEAudit':
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          audit: {
            ...state.writebackextreme.audit,
            ...defaultWriteBackExtreme.audit,
            ...action.payload,
          },
        },
      };

    case 'updateWBEColumnProperties':
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          columnProperties: {
            ...state.writebackextreme.columnProperties,
            [action.index]: {
              ...(state.writebackextreme.columnProperties[action.index] ?? {}),
              ...action.change,
            },
          },
        },
      };

    case 'updateWBEColumnConfig':
      //if state.writeBackExtreme.columnConfig is not defined, we need to define it
      if (!state.writebackextreme.columnConfig) {
        state.writebackextreme.columnConfig = {};
      }
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          columnConfig: {
            ...state.writebackextreme.columnConfig,
            [action.index]: {
              ...(state.writebackextreme.columnConfig[action.index] ?? {}),
              ...action.change,
            },
          },
        },
      };

    case 'updateWBECommentColor':
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          comments: {
            ...state.writebackextreme.comments,
            color: {
              ...state.writebackextreme.comments.color,
              ...action.change,
            },
          },
        },
      };

    case 'updateWBEAuditColor':
      return {
        ...state,
        writebackextreme: {
          ...state.writebackextreme,
          audit: {
            ...state.writebackextreme.audit,
            color: {
              ...state.writebackextreme.audit.color,
              ...action.change,
            },
          },
        },
      };
    default:
      return state;
  }
}

export function useSettings(origin?: 'dialog' | 'origin'): {
  settings: State;
  save: () => Promise<void>;
  dispatch: React.Dispatch<any>;
} {
  let [settings, dispatch] = useReducer(reducer, undefined, getInitialState);
  let [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!settings.datasheet) return;
    // most likely overfetching the parameters, don't think they will change
    // @todo: is there a difference between dashboard or worksheet parameters?
    Promise.all([getSummaryDataAsync(settings.datasheet, settings), settings.datasheet.getParametersAsync()]).then(
      ([summaryData, parameters]) => {
        summaryData.columns?.forEach(function (arrayItem, index) {
          var x = removeAgg(arrayItem.fieldName);
          if (summaryData.columns) {
            // @ts-ignore - We want the column name without the aggregation (SUM(ColumnName) -> ColumnName)
            summaryData.columns[index].fieldNameWithAgg = arrayItem._fieldName;
            // @ts-ignore
            summaryData.columns[index]._fieldName = x;
          }
        });
        dispatch({ type: 'setSheetData', summaryData, parameters });
      }
    );
  }, [settings.datasheet, settings.fetchMethod]);

  async function save() {
    if (saving) {
      return;
    }
    setSaving(true);
    try {
      await _save();
    } catch (e) {
      dispatch({ type: 'error', e });
      return Promise.reject(e);
    } finally {
      setSaving(false);
    }
  }

  function _save() {
    try {
      if (!extension.settings.get('datasheet')) {
        // set this setting only for new workbooks
        extension.settings.set('useNewParser', '1');
      }
      extension.settings.set('verticalAlignment', settings.verticalAlignment);
      extension.settings.set('origin', origin ?? '');
      extension.settings.set('datasheet', settings.datasheet.name);
      extension.settings.set('useParameterForDatasheet', JSON.stringify(settings.useParameterForDatasheet ?? false));
      extension.settings.set('datasheetParameter', settings.datasheetParameter ?? '');

      if (settings.theme) {
        extension.settings.set('theme', settings.theme);
      }

      // if (settings.popUpWorksheets) {
      extension.settings.set('popUpWorksheets', JSON.stringify(settings.popUpWorksheets));
      // }

      // if (settings.secondaryEventFilterWorksheets) {
      extension.settings.set('secondaryEventFilterWorksheets', JSON.stringify(settings.secondaryEventFilterWorksheets));
      // }

      extension.settings.set('excludeEventParameters', JSON.stringify(settings.excludeEventParameters));

      extension.settings.set('multiLayoutsParameters', JSON.stringify(settings.multiLayoutsParameters));

      extension.settings.set('unbalancedGroupColumn', JSON.stringify(settings.unbalancedGroupColumn));

      extension.settings.set('autoExpandGroups', String(settings.autoExpandGroups));
      extension.settings.set('enableUrlActionsforGroupedValues', String(settings.enableUrlActionsforGroupedValues));

      if (settings.groupUnbalancedGroupValue === '' || settings.groupUnbalancedGroupValue === null) {
        extension.settings.erase('groupUnbalancedGroupValue');
      } else {
        extension.settings.set('groupUnbalancedGroupValue', String(settings.groupUnbalancedGroupValue));
      }

      if (settings.expandAllButLastNGroups > 0) {
        extension.settings.set('expandAllButLastNGroups', String(settings.expandAllButLastNGroups));
      } else {
        extension.settings.erase('expandAllButLastNGroups');
      }

      if (settings.CSVFileName) {
        extension.settings.set('CSVFileName', String(settings.CSVFileName));
      } else {
        extension.settings.erase('CSVFileName');
      }

      if (settings.ExcelFileName) {
        extension.settings.set('ExcelFileName', String(settings.ExcelFileName));
      } else {
        extension.settings.erase('ExcelFileName');
      }
      if (settings.userWorksheet) {
        extension.settings.set('userWorksheet', JSON.stringify(settings.userWorksheet));
      } else {
        extension.settings.erase('userWorksheet');
      }

      if (settings.userColumn) {
        extension.settings.set('userColumn', JSON.stringify(settings.userColumn));
      } else {
        extension.settings.erase('userColumn');
      }

      if (settings.exportTopNRows > 0) {
        extension.settings.set('exportTopNRows', String(settings.exportTopNRows));
      } else {
        extension.settings.erase('exportTopNRows');
      }

      if (settings.rowHeight > 0) {
        extension.settings.set('rowHeight', String(settings.rowHeight));
      } else {
        extension.settings.erase('rowHeight');
      }

      if (settings.paginationPageSize > 0) {
        extension.settings.set('paginationPageSize', String(settings.paginationPageSize));
      } else {
        extension.settings.erase('paginationPageSize');
      }

      extension.settings.set('hideNewColumns', String(settings.hideNewColumns));
      extension.settings.set('showCompactMode', String(settings.showCompactMode));
      extension.settings.set('autoRowHeight', String(settings.autoRowHeight));
      extension.settings.set('charting', String(settings.charting));
      extension.settings.set('useTableauFont', String(settings.useTableauFont));
      extension.settings.set('useAccessibilityMode', String(settings.useAccessibilityMode));
      extension.settings.set('onlyNativeValues', String(settings.onlyNativeValues));
      extension.settings.set('showGroupBar', String(settings.showGroupBar));
      extension.settings.set('rowMultiSelectWithClick', String(settings.rowMultiSelectWithClick));
      extension.settings.set('rowMultiSelectEnabled', String(settings.rowMultiSelectEnabled));

      extension.settings.set('showSideBar', String(settings.showSideBar));
      extension.settings.set('showColumnsPanel', String(settings.showColumnsPanel));
      extension.settings.set('groupColumnsPanel', String(settings.groupColumnsPanel));
      extension.settings.set('showFiltersPanel', String(settings.showFiltersPanel));
      extension.settings.set('showFormulasPanel', String(settings.showFormulasPanel));
      extension.settings.set('showWbePanel', String(settings.showWbePanel));
      extension.settings.set('enableAdvancedFilter', String(settings.enableAdvancedFilter));
      extension.settings.set(
        'includeHiddenColumnsInAdvancedFilter',
        String(settings.includeHiddenColumnsInAdvancedFilter)
      );
      extension.settings.set('showColumnMenu', String(settings.showColumnMenu));
      extension.settings.set('showColumnMenu', String(settings.showColumnMenu));
      extension.settings.set('showFilterBar', String(settings.showFilterBar));
      extension.settings.set('showStatusBar', String(settings.showStatusBar));
      extension.settings.set('showGroupIncludeFooter', String(settings.showGroupIncludeFooter));
      extension.settings.set('showGroupIncludeTotalFooter', String(settings.showGroupIncludeTotalFooter));
      extension.settings.set('showGroupTotalAtTop', String(settings.showGroupTotalAtTop));
      extension.settings.set('showSubGroupTotalAtTop', String(settings.showSubGroupTotalAtTop));
      extension.settings.set('stickyGroupTotal', String(settings.stickyGroupTotal));
      extension.settings.set('enableRtl', String(settings.enableRtl));
      extension.settings.set('enableUnSortIcon', String(settings.enableUnSortIcon));
      extension.settings.set('enablePagination', String(settings.enablePagination));
      extension.settings.set('enableEventFilterChanged', String(settings.enableEventFilterChanged));
      extension.settings.set('enableEventMarkSelectionChanged', String(settings.enableEventMarkSelectionChanged));
      extension.settings.set('enableEventParameterChanged', String(settings.enableEventParameterChanged));
      extension.settings.set('enableMultiLayoutsWithParameter', String(settings.enableMultiLayoutsWithParameter));
      extension.settings.set('showGroupMultiColumn', String(settings.showGroupMultiColumn));
      extension.settings.set('showOpenedGroup', String(settings.showOpenedGroup));
      extension.settings.set('suppressGroupRowsSticky', String(settings.suppressGroupRowsSticky));
      extension.settings.set('showCheckbox', String(settings.showCheckbox));
      extension.settings.set('showPivotHeader', String(settings.showPivotHeader));
      extension.settings.set('showCustomNotification', String(settings.showCustomNotification));
      extension.settings.set('groupHideOpenParents', String(settings.groupHideOpenParents));
      extension.settings.set('groupSuppressCount', String(settings.groupSuppressCount));
      extension.settings.set('enablePopUp', String(settings.enablePopUp));
      extension.settings.set('enableAutoAggregation', String(settings.enableAutoAggregation));
      extension.settings.set('supressIndexColumn', String(settings.supressIndexColumn));
      extension.settings.set('showPopupOnData', String(settings.showPopupOnData));
      extension.settings.set('enablePivotRowTotals', String(settings.enablePivotRowTotals));
      extension.settings.set('shouldFormatExport', String(settings.shouldFormatExport));
      extension.settings.set('exportIncludeImages', String(settings.exportIncludeImages));
      extension.settings.set('exportLogging', String(settings.exportLogging));
      extension.settings.set('copyLogging', String(settings.copyLogging));
      extension.settings.set('showAggFuncInHeader', String(settings.showAggFuncInHeader));
      extension.settings.set('groupAggFiltering', String(settings.groupAggFiltering));
      extension.settings.set('pivotDateFormat', String(settings.pivotDateFormat));
      extension.settings.set('pivotHeaderIsDate', String(settings.pivotHeaderIsDate));
      extension.settings.set('doNotStorePivotOrder', String(settings.doNotStorePivotOrder));
      extension.settings.set('floatingExportButton', String(settings.floatingExportButton));
      extension.settings.set('floatingExportButtonsType', JSON.stringify(settings.floatingExportButtonsType));
      extension.settings.set('exportOddRowStyling', String(settings.exportOddRowStyling));
      extension.settings.set('groupUseUnbalancedGroups', String(settings.groupUseUnbalancedGroups));
      extension.settings.set('exportIncludeHeader', String(settings.exportIncludeHeader));
      extension.settings.set('exportColumnGroups', String(settings.exportColumnGroups));
      extension.settings.set('exportRowGroups', String(settings.exportRowGroups));
      extension.settings.set('exportVisibleColumns', String(settings.exportVisibleColumns));
      extension.settings.set('exportAllButLastOne', String(settings.exportAllButLastOne));
      extension.settings.set('exportFilteredColumns', String(settings.exportFilteredColumns));
      extension.settings.set('userCanChangeWidth', String(settings.userCanChangeWidth));
      extension.settings.set('userCanSortColumns', String(settings.userCanSortColumns));
      extension.settings.set('userCanOrderColumns', String(settings.userCanOrderColumns));
      extension.settings.set('userCanCopy', String(settings.userCanCopy));
      extension.settings.set('userCanAnonymize', String(settings.userCanAnonymize));
      extension.settings.set('customNotificationHeader', String(settings.customNotificationHeader));
      extension.settings.set('customNotificationText', String(settings.customNotificationText));
      extension.settings.set('userCanExport', String(settings.userCanExport));
      extension.settings.set('CSVExport', String(settings.CSVExport));
      extension.settings.set('ExcelExport', String(settings.ExcelExport));
      extension.settings.set('PDFExport', String(settings.PDFExport));

      // cleanup old columns
      let allColumns = [...settings.summaryData.columns, ...settings.calculatedColumns];
      let columnFields = allColumns.map(getField);

      let columnConfig: ConfigMap = {};
      for (let [field, format] of Object.entries(settings.columnConfig)) {
        if (Boolean(format) && columnFields.includes(field)) {
          // @ts-ignore This is for backwards compatibility
          columnConfig[field] = format;
        }
      }
      extension.settings.set('columnConfig', JSON.stringify(columnConfig));
      extension.settings.set('columnGroupConfig', JSON.stringify(settings.columnGroupConfig));

      extension.settings.set('calculatedColumns', JSON.stringify(settings.calculatedColumns));

      extension.settings.set('themeConfig', JSON.stringify(settings.themeConfig));

      extension.settings.set('actions', JSON.stringify(settings.actions));

      extension.settings.set('useTableauTooltip', JSON.stringify(settings.useTableauTooltip));
      extension.settings.set('tooltips', JSON.stringify(settings.tooltips));
      extension.settings.set('tooltipShowDelay', JSON.stringify(settings.tooltipShowDelay));
      extension.settings.set('tooltipHideDelay', JSON.stringify(settings.tooltipHideDelay));
      extension.settings.set('tooltipMouseTrack', JSON.stringify(settings.tooltipMouseTrack));
      extension.settings.set('tooltipInteraction', JSON.stringify(settings.tooltipInteraction));
      extension.settings.set('tooltipPadding', JSON.stringify(settings.tooltipPadding));
      extension.settings.set('tooltipBorderWidth', JSON.stringify(settings.tooltipBorderWidth));
      extension.settings.set('tooltipBorderRadius', JSON.stringify(settings.tooltipBorderRadius));
      extension.settings.set('tooltipBackgroundColor', JSON.stringify(settings.tooltipBackgroundColor));
      extension.settings.set('tooltipFontColor', JSON.stringify(settings.tooltipFontColor));
      extension.settings.set('tooltipBorderColor', JSON.stringify(settings.tooltipBorderColor));

      extension.settings.set('writebackextreme', JSON.stringify(settings.writebackextreme));

      extension.settings.erase('actionKeyColumn');
      extension.settings.erase('afterClearAction');
      extension.settings.erase('targetSheets');

      if (settings.rowColorColumn) {
        extension.settings.set('rowColorColumn', settings.rowColorColumn);
      } else {
        extension.settings.erase('rowColorColumn');
      }

      if (settings.fetchMethod) {
        extension.settings.set('fetchMethod', String(settings.fetchMethod));
      } else {
        extension.settings.set('fetchMethod', String(FETCH_METHODS.All_at_once));
      }

      if (settings.stateParameter) {
        extension.settings.set('stateParameter', settings.stateParameter);
      } else {
        extension.settings.erase('stateParameter');
      }
      return extension.settings.saveAsync();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return { settings, dispatch, save };
}

const defaultWriteBackExtreme: WriteBackExtreme = {
  connectionID: '',
  schemaID: '',
  primaryKeyColumn: '',
  primaryKeySlug: '',
  schemaName: '',
  url: '',
  username: null,
  comments: {
    enabled: false,
    showIndicator: true,
    color: {
      resolved: '#2BA7DF',
      unresolved: '#FF0079',
    },
    schemaID: '',
    schemaName: '',
    connectionID: '',
    primaryKeySlug: '',
    primaryKeyColumn: '',
  },
  audit: {
    enabled: true,
    color: {
      created: '#2BA7DF',
      updated: '#3B474F',
      deleted: '#FF0079',
    },
  },
  columnConfig: {},
  columnProperties: {},
};
