import { EventBus } from '@/utils/event-bus';
import { getGridState } from '@/utils/grid-state';
import { Column, ColumnGroup, GetContextMenuItemsParams, GetMainMenuItemsParams, GridApi } from 'ag-grid-community';
import pdfMake from 'pdfmake/build/pdfmake';
import { gridApi } from '.';
import { getSettings } from '../settings';
import { generateAllBase64ImagesFromArray } from '../utils/functional';
import { expandAllButLastNGroups, isGroupColumn, sortGroupsByCount, sortGroupsByValueWithDates } from '../utils/grid';
import { clearAgFilter, setAgFilter } from '../utils/grid-filter';
import { forEachRangeSelectedNodes } from '../utils/grid-selection';
import imageCellRenderer from './cellRenderers/imageCellRenderer';
import getDocDefinition from './docDefinition';
import { showAGGrid } from './interface';
import MenuBuilder from './menu-builder';
import { gridOptions } from './options';
import pdfFonts from './vfs_fonts';
import { useWBEStore } from '@/writeback/store';

pdfMake.vfs = pdfFonts;

const tableau = window.tableau;
const extension = tableau.extensions;
let CURRENT_USER: string;
const isEnterprise = import.meta.env.VITE_PRODUCT === 'e';

export function getContextMenuItems(params: GetContextMenuItemsParams) {
  let { value, column, api, defaultItems, context } = params;

  const {
    columnProperties,
    schemaID: writeBackSchemaID,
    comments: { enabled: commentsEnabled },
    nameMap,
    writeData,
  } = useWBEStore.getState();

  if (!column || !api) return [];
  const isAuthoring = extension.environment.mode === 'authoring';

  if (!!extension.worksheetContent) {
    extension.worksheetContent.worksheet.hoverTupleAsync(-1);
  }

  const settings = getSettings();
  if (!settings) return [];
  const userCanCopy = settings.userCanCopy ? true : false;
  const userCanExport = settings.userCanExport ? true : false;
  const userCanExportPDF = settings.userCanExport ? (settings.PDFExport ?? false) : false;
  const userCanExportExcel = settings.userCanExport ? (settings.ExcelExport ?? false) : false;
  const userCanExportCSV = settings.userCanExport ? (settings.CSVExport ?? false) : false;
  const userCanAnonymize = settings.userCanAnonymize ? true : false;

  // remove undesirable default context menu items
  let defaults = (defaultItems || []).filter(
    (item) =>
      item !== 'paste' &&
      item !== 'copy' &&
      item !== 'copyWithHeaders' &&
      item !== 'export' &&
      item !== 'copyWithGroupHeaders' &&
      item !== 'separator'
  );

  let menu = new MenuBuilder(defaults);

  const selectedCells = api.getCellRanges();
  const selectedRowNodes = api.getSelectedNodes();

  if (userCanExport || userCanExportPDF || isAuthoring) {
    menu.addSeparator();
    if (userCanExportPDF || isAuthoring) {
      menu.addActionWithIcon('Export to PDF', 'save', () => exportPDF(api));
    }

    if (userCanExportExcel || userCanExportCSV || isAuthoring) {
      let exportMenu = new MenuBuilder();

      if (userCanExportExcel || isAuthoring) {
        exportMenu.addActionWithIcon('Excel Export (.xlsx)', 'excel', async () => {
          exportExcel(api, context);
        });
      }
      if (userCanExportCSV || isAuthoring) {
        exportMenu.addActionWithIcon('CSV Export', 'csv', () => exportCSV(api));
      }
      // TODO: Sri check this? submenu is not working
      menu.addActionWithIcon('Export', 'save', undefined, { subMenu: exportMenu.build() });
    }
    // make sure the export menu is at the bottom
    menu.menu.reverse();
  }

  if (userCanCopy || isAuthoring) {
    const copyData = async (options: {}, type: string) => {
      let allowed = true;
      if (isEnterprise && settings.copyLogging) {
        let message;
        if (type.toLowerCase() === 'range') {
          const selectedRowsTotal = selectedCells?.reduce((acc, cell) => {
            const endrowIndex = cell?.endRow?.rowIndex ?? 0;
            const startRowIndex = cell?.startRow?.rowIndex ?? 0;
            return acc + endrowIndex - startRowIndex + 1;
          }, 0);
          const selectedColumnsTotal = selectedCells?.reduce((acc, cell) => {
            return new Set([...acc, ...cell.columns.map((c) => c.getColId())]);
          }, new Set()).size;

          message = `${selectedRowsTotal} row(s) of ${selectedColumnsTotal}`;
        } else {
          try {
            const checkboxSelectedRowsTotal = selectedRowNodes.length;
            if (!checkboxSelectedRowsTotal) throw new Error('No rows selected');

            const checkboxSelectedColsTotal = api.getAllDisplayedColumns().length;
            message = `${checkboxSelectedRowsTotal} row(s) of ${checkboxSelectedColsTotal}`;
          } catch (e) {
            console.error(e);
            return;
          }
        }
        if (!CURRENT_USER && isEnterprise) CURRENT_USER = (await settings.getUsernameAsync()) ?? 'Unknown user';
        // Log the message to the server
        allowed = await logToServer('Clipboard', message, 'column(s)');
      }
      if (allowed) {
        api[`copySelected${type}ToClipboard`](options);
      }
    };

    const expandPivot = (expand: boolean) => {
      const state = api.getColumnGroupState();
      const expandedState = state.map((group) => ({
        groupId: group.groupId,
        open: expand,
      }));
      api.setColumnGroupState(expandedState);
    };

    const expandCol = (column: ColumnGroup | Column, expand: boolean) => {
      const parent = column.getParent();
      if (!parent) return;

      expandCol(parent, expand);
      api.setColumnGroupOpened(parent.getGroupId(), expand);
    };

    menu.addActionWithIcon('Copy Cell(s) with Group Headers', 'copy', () => {
      copyData({ includeHeaders: true, columnGroups: true }, 'Range');
    });
    menu.addActionWithIcon('Copy Rows(s) with Headers', 'copy', () => {
      copyData({ includeHeaders: true }, 'Rows');
    });
    menu.addActionWithIcon('Copy Row(s)', 'copy', () => {
      copyData({ includeHeaders: false }, 'Rows');
    });
    // how come this behaviour differs from ctrl+c ??
    // { shortcut: 'Ctrl+C' }

    menu.addActionWithIcon('Copy Cell(s)', 'copy', () => {
      copyData(false, 'Range');
    });

    menu.addSeparator();

    const technicalName = nameMap.forwardMap.get(column.getColId()) ?? column.getColId();
    const columnProps = columnProperties[technicalName];
    if (writeBackSchemaID && !api.isPivotMode()) {
      if (columnProps?.editable) {
        menu.addAction('Reset value', () => {
          writeData(params.api, params.column, null);
        });

        menu.addAction('Show audits for this cell', () => {
          useWBEStore.setState({ selectedTab: 'Audits' });
          api.openToolPanel('writeback');
        });
      }

      if (commentsEnabled) {
        menu.addAction('Show comments on this cell', () => {
          // function to comment on a cell
          useWBEStore.setState({ selectedTab: 'Comments' });
          api.openToolPanel('writeback');
        });
      }

      if (columnProps?.editable || commentsEnabled) {
        menu.addSeparator();
      }
    }

    if (api.isPivotMode()) {
      menu.addSeparator();

      menu.addAction('Reorder All Pivots', async () => {
        const gridState = await getGridState(api, settings.stateParameter!);

        delete gridState.columnGroupState;
        gridState.columnState = gridState.columnState?.filter(
          // @ts-ignore
          (c) => !(c.colId?.startsWith('pivot') || c.groupId !== undefined)
        );

        settings.gridState = gridState;

        gridApi?.destroy();
        const options = {
          updateColumnDefs: false,
          theme: settings.theme,
          showCompactMode: settings.showCompactMode,
          useTableauFont: settings.useTableauFont,
          useAccessibilityMode: settings.useAccessibilityMode,
          fetchMethod: settings.fetchMethod,
          groupColumnsPanel: settings.groupColumnsPanel,
        };

        showAGGrid(gridOptions, gridState, options);
        gridApi?.setGridOption('pivotMode', true);

        // Make sure we save the new state
        EventBus.triggerEvent('stateChanged');
      });

      menu.addAction('Collapse All Pivots', () => {
        expandPivot(false);
      });

      menu.addAction('Expand All Pivots', () => {
        expandPivot(true);
      });

      menu.addAction('Collapse Pivot', () => {
        expandCol(column, false);
      });

      menu.addAction('Expand Pivot', () => {
        expandCol(column, true);
      });

      menu.addSeparator();
    }
  }

  if (isGroupColumn(column)) {
    // @ts-ignore: sortController is meant to be private but we need it
    const sortController = api.sortController;
    const currentColDef = column.getColDef();

    // TODO: Should check if isAuthor
    menu.addActionWithIcon('Hide/Show Group Count', 'eye-slash', () => {
      let toggle: boolean = JSON.parse(
        extension.settings.get('groupSuppressCount')
          ? (extension.settings.get('groupSuppressCount') ?? 'false')
          : 'false'
      );
      extension.settings.set('groupSuppressCount', (!toggle).toString());
      extension.settings.saveAsync();
    });

    menu.addSubMenu('Sort Grouped by', (subMenu) => {
      subMenu.addActionWithIcon('Count (asc)', 'asc', () => {
        currentColDef.comparator = sortGroupsByCount;
        sortController.setSortForColumn(column, 'asc', false);
        currentColDef.comparator = sortGroupsByValueWithDates;
      });

      subMenu.addActionWithIcon('Count (desc)', 'desc', () => {
        currentColDef.comparator = sortGroupsByCount;
        sortController.setSortForColumn(column, 'desc', false);
        currentColDef.comparator = sortGroupsByValueWithDates;
      });
    });

    return menu.build();
  }

  if (userCanAnonymize || isAuthoring) {
    menu.addActionWithIcon('Anonymize', 'eye-slash', () => {
      forEachRangeSelectedNodes(api, ({ columns, rowNode }) => {
        for (let column of columns) {
          // @todo: this doesn't work for calculated columns for some reason
          rowNode.setDataValue(column, 'NN');
        }
      });
    });
  }

  if (column.isFilterAllowed()) {
    menu.addSeparator();

    let filter = api.getColumnFilterInstance(column);
    if (column.isFilterActive()) {
      menu.addAction('Clear Filter', () => {
        clearAgFilter(api, filter);
      });
    }

    menu.addActionWithIcon('Refresh data', 'reload', () => {
      extension.settings.set('updateFromContextMenu', 'true');
      extension.settings.saveAsync();
    });

    menu.addActionWithIcon('Clear selection', 'not-allowed', () => {
      api.clearRangeSelection();
      api.forEachNodeAfterFilter((node) => {
        // select the node
        node.setSelected(false);
      });
    });

    menu.addActionWithIcon('Clear all filter(s)', 'not-allowed', () => {
      api.setFilterModel(null);
      api.setAdvancedFilterModel(null);
    });

    const inPivotMode = api?.isPivotMode();
    if (!inPivotMode && filter) {
      menu.addActionWithIcon('Exclude', 'cross', () => {
        setAgFilter(api, column, filter, value, { exclude: true });
      });

      menu.addActionWithIcon('Keep Only', 'tick', () => {
        setAgFilter(api, column, filter, value);
      });
    }
  }

  return menu.build();
}

async function logToServer(exportType: string, amount?: number | string, type: string = 'rows') {
  //Params seem to be optional
  const extension = window.tableau.extensions;
  const isVizExtension = !!extension.worksheetContent;
  const name = isVizExtension ? extension.worksheetContent?.worksheet.name : extension.dashboardContent?.dashboard.name;
  const message = `${await CURRENT_USER} is exporting ${amount ?? 'all'} ${type} from ${name} to ${exportType}`;

  const request = await fetch('/log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      message,
    }),
  });
  return request.ok;
}

async function exportData(
  exportType: 'Excel' | 'Csv',
  api: GridApi,
  fileName: string,
  selectedRowsCount: number | 'all',
  hasSelectedRows: boolean,
  exportLogging: boolean
) {
  if (!exportLogging || (await logToServer(exportType, selectedRowsCount))) {
    try {
      const exportProperty = `exportDataAs${exportType}` as 'exportDataAsExcel' | 'exportDataAsCsv';
      api[exportProperty]({
        onlySelected: hasSelectedRows,
        fileName,
      });
    } catch (error) {
      console.error('Something went wrong while exporting: ', error);
    }
  }
}

export async function exportExcel(api: GridApi, context?: any) {
  const selectedRows = api.getSelectedRows();
  const hasSelectedRows = selectedRows.length > 0;
  const selectedRowsCount = hasSelectedRows ? selectedRows.length : 'all';

  let settings = getSettings();
  const fileName = settings ? (settings.ExcelFileName ?? 'SuperTable') : 'SuperTable';

  if (isEnterprise) {
    if (!gridOptions || !api) return;

    const shouldExportImages = !!settings?.exportIncludeImages;

    if (shouldExportImages) {
      const exportGenerate = document.getElementById('exportGenerate');
      if (exportGenerate) exportGenerate.style.display = '';

      // If we are on enterprise, we need to get the images and save them
      // Get all the columns, gridOptions.columnDefs doesn't work because it doesn't include the user provided column definitions
      const imageColumns =
        api
          .getColumns()
          ?.map((col) => col.getUserProvidedColDef())
          .filter((col) => col?.cellRenderer === imageCellRenderer)
          .map((col) => col?.field)
          .filter(Boolean) ?? [];

      // If there are images, we need to get them and save them
      // We need to get all the image urls from the grid
      const rowData = selectedRows.length > 0 ? selectedRows : gridOptions.rowData;
      const imageData = (gridOptions.rowData
        ?.map((row) => {
          return Object.entries(row)
            .map(([key, value]) => {
              if (imageColumns.includes(key)) {
                return value;
              }
            })
            .filter((obj) => obj);
        })
        .filter((obj) => obj.length > 0)
        .filter(Boolean) ?? []) as string[][];

      // Store all the base64 images in the context so we can use them in the export
      // We need to do this because the export is async and we can't wait for it to finish
      let listofbase64: { [key: string]: string } = {};
      try {
        listofbase64 = (await generateAllBase64ImagesFromArray(imageData, context?.base64 ?? {})) ?? {};
      } catch (error) {
        console.error('Error generating base64 images: ', error);
      }

      context.base64 = listofbase64;
      gridOptions.context = context;
    }

    const percentage = document.getElementById('ExportPercentageComplete');
    if (percentage) percentage.innerHTML = '<strong>Loading...</strong>';

    if (!CURRENT_USER) {
      CURRENT_USER = settings ? ((await settings.getUsernameAsync()) ?? 'Unknown user') : 'Unknown user';
    }
    if (settings) {
      exportData('Excel', api, fileName, selectedRowsCount, hasSelectedRows, settings.exportLogging);
    }

    setTimeout(() => {
      if (exportGenerate) exportGenerate.style.display = 'none';
    }, 1000);
  } else {
    api.exportDataAsExcel({
      onlySelected: hasSelectedRows,
      fileName,
    });
  }
}
//Todo: Check if filename is unneecessary
export async function exportCSV(api: GridApi) {
  const selectedRows = api.getSelectedRows();
  const hasSelectedRows = selectedRows.length > 0;
  const selectedRowsCount = hasSelectedRows ? selectedRows.length : 'all';

  let settings = getSettings();
  const fileName = settings?.CSVFileName ?? 'SuperTable';

  if (isEnterprise) {
    if (!CURRENT_USER && isEnterprise) {
      CURRENT_USER = settings ? ((await settings.getUsernameAsync()) ?? 'Unknown user') : 'Unknown user';
    }
    if (settings) {
      exportData('Csv', api, fileName, selectedRowsCount, hasSelectedRows, settings.exportLogging);
    }
  } else {
    api.exportDataAsCsv({
      onlySelected: hasSelectedRows,
      fileName,
    });
  }
}
//TDOD: COnfirm with sri if the html elements are never null
export async function exportPDF(api: GridApi) {
  const settings = getSettings();
  if (!CURRENT_USER && isEnterprise) {
    CURRENT_USER = settings ? ((await settings.getUsernameAsync()) ?? 'Unknown user') : 'Unknown user';
  }
  const isDesktop = extension.environment.context === 'desktop';

  const exportGenerate = document.getElementById('exportGenerate');
  if (exportGenerate) {
    exportGenerate.style.display = '';
  }

  setTimeout(async () => {
    // if we are on enterprise, log to server
    if (!isEnterprise || (settings && !settings.exportLogging) || (await logToServer('PDF'))) {
      document.getElementById('ExportPercentageComplete')!.innerHTML = '<strong>Loading...</strong>';
      const docDefinition = await getDocDefinition(api);
      // add .pdf if you are in desktop
      if (isDesktop) {
        pdfMake.createPdf(docDefinition).download('SuperTable.pdf');
      } else {
        pdfMake.createPdf(docDefinition).download('SuperTable');
      }
      setTimeout(() => {
        exportGenerate!.style.display = 'none';
      }, 1000);
    } else {
      document.getElementById('ExportPercentageComplete')!.innerHTML = '<strong>Failed to export to PDF</strong>';
    }
  }, 500);
}

export function getMainMenuItems({ column, api, defaultItems }: GetMainMenuItemsParams) {
  if (!column || !api) return [];

  // remove resetColumns, its confusing and doesn't work properly
  let index = defaultItems.indexOf('resetColumns');
  if (index > -1) {
    defaultItems.splice(index, 1);
  }

  index = defaultItems.indexOf('autoSizeAll');
  if (index > -1) {
    defaultItems.splice(index, 1);
  }

  let menu = new MenuBuilder(defaultItems, 'forward');

  menu.addActionWithIcon('Autosize All Columns', 'resize', () => {
    api.showLoadingOverlay();
    api.sizeColumnsToFit();
    api.autoSizeAllColumns();
    api.hideOverlay();
  });

  menu.addActionWithIcon('Fit columns to window', 'resize', () => {
    api.sizeColumnsToFit();
  });

  menu.addAction('Expand All (-1)', () => {
    expandAllButLastNGroups(api, 1);
  });
  menu.menu.forEach((item, index) => {
    if (typeof item === 'object' && 'name' in item && item.name === 'Fit columns to window') {
      menu.menu.splice(index, 1);
      menu.menu.splice(4, 0, item);
    }

    if (typeof item === 'object' && 'name' in item && item.name === 'Autosize All Columns') {
      menu.menu.splice(index, 1);
      menu.menu.splice(3, 0, item);
    }
  });

  return menu.build();
}
