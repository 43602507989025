import { debounce } from '@/utils/functional';
import {
  CellClassParams,
  ColDef,
  ExcelStyle,
  ICellRendererParams,
  IRowNode,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
  Column as AgColumn,
} from 'ag-grid-community';
import { isValid as isValidDate } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { linkCellRenderer } from '../../utils/grid';
import { removeAgg } from '../../utils/tableau';
import TooltipGrid from '../Tooltip';
import { compileCalculation, createTextInterpolator } from '../calculation';
import { createFormatter, createNumberFormatter, createStringFormatter, getNumericValue } from '../formatters';
import { gridOptions } from '../options';

import { Calculation, STColDef, ValueFormatter } from '@/types/mapping';
import { STColumnType } from '@/types/writeback';
import { useWBEStore } from '@/writeback/store';
import { Column } from '@tableau/extensions-api-types';
import { HeaderValueGetterParams } from 'ag-grid-community';
import { Settings } from '../../settings';
import { ColMapper, ColumnConfig, ConfigMap, FormatConfig } from '../../types/settings';
import DateTimePickerEditor from '@/utils/flatPicker';
import {
  BarchartCellRenderer,
  EmojiCellRenderer,
  HTMLCellRenderer,
  ImageCellRenderer,
  MarkdownCellRenderer,
} from '../cellRenderers';
import { createCellStyler } from './createStyler';
import { makeCellClass } from './makeCellClass';
import { createCustomComparator, getPivotHeaderSorter } from './pivot';
import {
  getField,
  getFractionDigits,
  stripParentheses,
  isCalculation,
  isDate,
  isMeasure,
  dateComparator,
} from './utils';
import { gridApi } from '..';
import CustomLargeTextCellEditor from '../cellEditors/TextareaEditor';
import CustomSelectCellEditor from '../cellEditors/SelectEditor';
import formatDate from '@/utils/dateFormat';

const { isValid, valueSetter, nameMap } = useWBEStore.getState();

const defaultAllowedAggregations = ['sum', 'min', 'max', 'count', 'CountD', 'avg', 'first', 'last', 'SelectiveAgg'];
const defaultAllowedAggregationsDimension = ['count', 'CountD', 'first', 'last', 'List'];

dayjs.extend(customParseFormat);

export function createColumnMapper(columnConfig: ConfigMap, settings: Settings): ColMapper {
  return function (column: Column | Calculation, index, columns) {
    let isAuthoring = window.tableau.extensions.environment.mode === 'authoring';

    let rawFieldName = getField(column);
    let field = removeAgg(rawFieldName);

    let baseColConfig: ColumnConfig = isMeasure(column)
      ? { colorCellBackground: true, color: { type: 'continuous' } }
      : { colorCellBackground: true, color: { type: 'discrete', map: {} } };
    let config: ColumnConfig = columnConfig[field] || columnConfig[rawFieldName] || baseColConfig;

    let formatter = createFormatter(column, config.format, config);
    let quickTableValueGetter:
      | ((params: { node?: IRowNode | null; value: number; column?: AgColumn; colDef: ColDef }) => any)
      | ((params: ValueFormatterParams<any, any>) => any);
    if (config?.quickTableCalc === 'percentOfTotal' && isMeasure(column)) {
      if (formatter) {
        quickTableValueGetter = (params: {
          node?: IRowNode | null;
          value: number;
          column?: AgColumn;
          colDef: ColDef;
        }) => {
          if (params.node?.level === -1) {
            return 1;
          }

          let value = getNumericValue(params);
          let total: number;
          const fieldToCheck = params.colDef?.field ?? params?.column?.getColId() ?? field;
          if (config?.quickTableCalcSettings?.useSubTotalForPercent) {
            total = getAggDataValue(params.node?.parent, fieldToCheck) ?? 0;
          } else {
            let nodeToCheck = params.node?.parent;
            while (nodeToCheck?.parent) {
              nodeToCheck = nodeToCheck.parent;
            }
            total = getAggDataValue(nodeToCheck, fieldToCheck) ?? 0;
          }
          value /= total;

          return value;
        };

        const oldFormatter = formatter;

        formatter = (params) => {
          params.value = quickTableValueGetter(params);
          return oldFormatter(params);
        };
      }
    }

    let aggFunc = getAggregation(
      column,
      isMeasure(column) ? (config.showAggregation ?? true) : (config.showDimensionAggregation ?? false),
      config.defaultAggregation ?? (isMeasure(column) ? 'sum' : 'count')
    );

    const enableValueAggMenu =
      typeof config.enableValueAggMenu === 'undefined' ? isMeasure(column) : config.enableValueAggMenu;

    const enableValue = isMeasure(column)
      ? 'showAggregation' in config
        ? config.showAggregation
        : true
      : 'showDimensionAggregation' in config
        ? config.showDimensionAggregation
        : false;

    let baseColDef: STColDef = {
      field,
      dataType: column.dataType || 'string', // this is checked in the calculation
      headerName: stripParentheses(column as Column),
      headerClass: [
        isCalculation(column)
          ? `ag-header-${removeAgg(column.index).replace(/[^a-zA-Z0-9-_]/g, '')}`
          : `ag-header-${removeAgg(config.fieldName || column.fieldName).replace(/[^a-zA-Z0-9-_]/g, '')}`,
        config.headerAlignment ? 'ag-' + config.headerAlignment + '-aligned-header' : 'ag-left-aligned-header',
        isCalculation(column)
          ? `excel-header-${removeAgg(column.index).replace(/[^a-zA-Z0-9-_]/g, '')}`
          : `excel-header-${removeAgg(config.fieldName || column.fieldName).replace(/[^a-zA-Z0-9-_]/g, '')}`,
      ],
      tooltipField: 'index',
      tooltipComponent: TooltipGrid,
      tooltipFormatter: formatter, // used by the tooltip to get the formatted value
      headerAlignment: config.headerAlignment || 'left',
      headerValueGetter: function (params: HeaderValueGetterParams) {
        if (config.hideHeaderName && params.location === 'header') {
          return '';
        }
        let header = params.colDef.headerName;

        // if someone overwrites fieldName
        if (config.fieldName && config.fieldName !== header) {
          header = config.fieldName;
        }

        if (!header && 'field' in params.colDef && typeof params.colDef.field === 'string') {
          header = params.colDef.field;
        }

        if (settings.showAggFuncInHeader && params.column?.getAggFunc()) {
          let columns = params.api.getColumnState();
          let isGrouping = 0;
          // make sure to loop only once and not for every column.
          for (var i = 0; i < columns.length; i++) {
            if (params.api.getColumn(columns[i].colId) && params.api.getColumn(columns[i].colId)?.isRowGroupActive()) {
              isGrouping = 1;
              break;
            }
          }
          if (isGrouping) {
            header += ' (' + params.api.getColumn(field)?.getAggFunc() + ')';
          }
        }
        return header ?? '';
      },
      headerTooltip: config?.tooltip,
      columnGroupShow: config?.groupShowOpen ? 'open' : undefined,
      width: 200, // ag-grid default, to make resetColumnState behave properly
      unSortIcon: settings.enableUnSortIcon,
      suppressColumnsToolPanel: config?.suppressColumn,
      ...getFilter(column, config, formatter, settings.showFilterBar),
      valueFormatter: formatter,
      keyCreator: formatter,
      pdfExportOptions: {
        valueFormatter: formatter,
        createURL: config?.urlExpression ? createTextInterpolator(config.urlExpression, columns) : undefined,
        isImage: config && config.format && config.format.style === 'image',
      },
      suppressHeaderMenuButton: !settings.showColumnMenu,
      cellStyle: createCellStyler(column, config?.color, config?.colorCellBackground, config, settings),
      autoHeight: settings.autoRowHeight && !isMeasure(column) ? true : false,
      wrapText: settings.autoRowHeight && !isMeasure(column) ? true : false,
      suppressMovable: !settings.userCanOrderColumns && !isAuthoring,
      resizable: settings.userCanChangeWidth || isAuthoring,
      sortable: settings.userCanSortColumns || isAuthoring,
      enableValue,
      defaultAggFunc: (config.defaultAggregation ?? isMeasure(column)) ? 'sum' : 'count',
      aggFunc: config?.quickTableCalc === 'percentOfTotal' ? 'PoT' : aggFunc,
      allowedAggFuncs:
        config?.quickTableCalc === 'percentOfTotal'
          ? ['PoT']
          : enableValueAggMenu
            ? isMeasure(column)
              ? defaultAllowedAggregations
              : defaultAllowedAggregationsDimension
            : aggFunc
              ? [aggFunc]
              : [], //Could be cleaner would require changing get aggregation function return type

      cellClass: (params) => makeCellClass(config, column, settings, params),
      cellClassRules: {
        'cell-wrap-text': () => {
          return settings.autoRowHeight;
        },
        'url-cell': (params) => {
          if (params.colDef.field && params.data) {
            let url = createTextInterpolator(settings.columnConfig[params.colDef.field]?.urlExpression ?? '', columns);
            let interpolatedUrl = url?.(params.data);
            return interpolatedUrl ? interpolatedUrl.length > 0 && interpolatedUrl !== '%null%' : false;
          }
          return false;
        },
        groupRows: (params) => {
          return params.node.group ?? false; //Check if group is present otherwise return false, check with sri
        },
        borders: (params) => {
          return !params.node.group;
        },
        isString: function () {
          return !isMeasure(column);
        },
        'hidden-total': function () {
          return config && (config.hideTotal ?? false); //Check if group is present otherwise return false, check with sri
        },
        'cell-align-right': function () {
          return (
            (isMeasure(column) && (!config.alignment || config.alignment === 'auto')) || config.alignment === 'right'
          );
        },
        'cell-align-left': function () {
          return config.alignment === 'left';
        },
        'cell-align-center': function () {
          return config.alignment === 'center';
        },
        'is-barchart': function () {
          return doesColumnDisplayBarChart(config, column, columns);
        },
        'total-group-row-cell': function (params) {
          return params.node.level === -1 || params.node?.rowPinned === 'top';
        },
        'subtotal-group-row-cell': function (params) {
          return (params.node.level !== -1 && params.node?.footer && (params.node.group ?? false)) ?? false; //Evaluate to false if group is not present
        },
      },
      cellRendererParams: () => {
        return {
          // Is this even used?
          footerValueGetter: (params: ICellRendererParams) => {
            const isRootLevel = params.node.level === -1;
            if (isRootLevel) {
              return gridOptions.localeText?.total || 'Total';
            }
            return (gridOptions.localeText?.subTotal || 'Sub total') + ' ' + params.value;
          },
          suppressCount: settings.groupSuppressCount,
        };
      },
      sort: config.defaultSort ? (config.defaultSortingOrder ?? 'asc') : undefined,
      // HACK: So we can access this during the export to excel
      quickTableValueGetter,
    };

    // add a selective aggregation flag column map to the context
    if (config?.selectiveAggFlagColumn) {
      gridOptions.context = {
        ...gridOptions.context,
        selectiveAggregationMap: {
          ...gridOptions.context?.selectiveAggregationMap,
          [field]: config.selectiveAggFlagColumn,
        },
      };
    }

    if (column.dataType === 'string') {
      const colSettings = settings.columnConfig[column.fieldName];

      if (colSettings?.useCustomSort) {
        const custom = createCustomComparator(colSettings, column.fieldName);
        if (!custom) {
          console.warn(
            `Custom sort order for column ${column.fieldName} is not valid. Falling back to default sort order.`
          );
        } else {
          baseColDef.comparator = custom;
        }
      }
      // else it used to be new Intl.Collator(undefined, { usage: 'sort' }).compare;
      // now using default ag-grid sorting
    } else {
      baseColDef.comparator = createComparator(column, settings);
    }

    if (isCalculation(column)) {
      baseColDef.cellDataType = 'number';
    } else if (settings.writebackextreme.primaryKeyColumn !== column.fieldName) {
      switch (column.dataType as STColumnType) {
        case 'string':
          baseColDef.cellDataType = 'text';
          baseColDef.cellEditor = 'agTextCellEditor';
          baseColDef.valueFormatter = (params) => {
            const isGroupedNode = params.node && params.node.group;
            if (isGroupedNode) {
              // If it's in grouping mode, the value comes from aggFunc
              const aggValue = params.value; // aggregated value
              return aggValue; // Format the aggregated value
            } else if (params && params.value) {
              // For non-grouped rows, handle normal values
              return createStringFormatter(config?.format?.nullReplaceString)(params);
            }

            return params.value; // Default return when no value is available
          };

          break;
        case 'content':
          baseColDef.cellDataType = 'text';
          baseColDef.cellEditor = CustomLargeTextCellEditor;
          baseColDef.cellEditorPopup = true;
          baseColDef.comparator = (valueA, valueB) => {
            return (valueA ?? '').localeCompare(valueB ?? '');
          };
          break;
        case 'number':
          baseColDef.cellDataType = 'number';
          baseColDef.cellEditor = 'agNumberCellEditor';
          baseColDef.cellEditorParams = {
            precision: 0,
          };
          break;
        case 'float':
          baseColDef.cellDataType = 'number';
          baseColDef.cellEditor = 'agNumberCellEditor';
          baseColDef.cellEditorParams = {
            precision: column?.precision ?? 2,
          };
          break;
        case 'date':
          baseColDef.cellDataType = 'date';
          baseColDef.cellEditor = DateTimePickerEditor;
          baseColDef.cellEditorParams = {
            pickerType: 'date',
            format: config.format?.date || 'yyyy-MM-dd',
          };

          baseColDef.comparator = dateComparator;
          break;
        case 'datetime':
        case 'date-time':
          baseColDef.cellDataType = 'string';
          baseColDef.cellEditor = DateTimePickerEditor;
          baseColDef.cellEditorParams = {
            pickerType: 'datetime',
            format: config.format?.date || 'yyyy-MM-dd HH:mm:ss',
          };
          //new comparator for datetime columns
          baseColDef.comparator = dateComparator;
          baseColDef.valueFormatter = (params) => {
            return params.value ? formatDate(params.value, config.format?.date || 'yyyy-MM-dd HH:mm') : '';
          };

          break;
        case 'boolean':
          baseColDef.cellDataType = 'boolean';
          baseColDef.cellEditor = '';
          break;
        case 'select':
          baseColDef.cellEditor = CustomSelectCellEditor;
          baseColDef.cellEditorParams = {
            options: column.options || {},
          };
          baseColDef.valueFormatter = (params) => {
            return column.options?.[params.value] || params.value; // Fallback to key if no match is found
          };
          baseColDef.comparator = (valueA, valueB) => {
            const displayValueA = column.options?.[valueA] || '';
            const displayValueB = column.options?.[valueB] || '';

            // Perform a normal string comparison for sorting the display values
            return displayValueA.localeCompare(displayValueB);
          };
          break;
        default:
          baseColDef.cellDataType = undefined;
      }
      const technicalName = nameMap.forwardMap.get(rawFieldName);
      if (technicalName) {
        const columnProperties = settings.writebackextreme.columnProperties?.[technicalName];
        if (columnProperties) {
          baseColDef.field = rawFieldName; // Name without applying removeAgg(). This allows us to have () in our column name
          baseColDef.editable =
            isValid() && columnProperties.editable && (!config?.quickTableCalc || config?.quickTableCalc === 'none');
          baseColDef.valueSetter = valueSetter;
        }
      }
    }

    if (config && config.format && config.format.style === 'emoji') {
      return {
        ...baseColDef,
        cellRenderer: EmojiCellRenderer,
        enableRowGroup: true,
      };
    }
    if (config && config.format && config.format.style === 'md') {
      return {
        ...baseColDef,
        cellRenderer: MarkdownCellRenderer,
        enableRowGroup: true,
      };
    }
    // html
    if (config && config.format && config.format.style === 'html') {
      return {
        ...baseColDef,
        cellRenderer: HTMLCellRenderer,
        enableRowGroup: true,
      };
    }
    if (config && config.format && config.format.style === 'image') {
      return {
        ...baseColDef,
        cellRenderer: ImageCellRenderer,
        enableRowGroup: true,
        cellRendererParams: {
          ...baseColDef.cellRendererParams,
          //TODO: Fix type error for now ts ignore
          //@ts-ignore
          url: createTextInterpolator(config.urlExpression, columns),
          format: config.format,
        },
      };
    }
    // Adding Excel data typing for not formatted export
    // No excel format for the time style
    if (
      (config && isMeasure(column) && (!config.format || !('style' in config.format))) ||
      (config.format?.style && config.format.style !== 'time' && config.format.style !== 'string')
    ) {
      let configStyle = config.format || {};
      const style = configStyle?.style || 'decimal';

      let fractionDigits = getFractionDigits(column, configStyle);

      let formatOptions = {
        style,
        // currency property needs to be set if style is currency
        // defaulting to USD is kind of strange though...
        currency: style === 'currency' ? configStyle.currency || 'USD' : undefined,
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
        useGrouping: configStyle.useGrouping,
        // Don't use the separator when formatting for excel
        // since we need the original value in excel
        separators: {},
      };

      let truncater = {
        truncateValues: false,
        truncateMinValue: null,
        truncateMaxValue: null,
      };

      let formatter = createNumberFormatter(formatOptions, configStyle, truncater, true);
      let formatPositive;
      let formatNegative;
      const percent = style === 'percent' ? 100 : 1;
      // https://www.ablebits.com/office-addins-blog/custom-excel-number-format/
      // Convert supertables format from the config to custom excel number format
      // Use 1000 + Math.PI to get the number of digits and the decimal separator
      // Replace thie first 3 digits with # and the rest with 0 this way the thousands seperator is included
      formatPositive = formatter({ value: (1000 + Math.PI) / percent })
        .replace(/[0-9]/, '#')
        .replace(/[0-9]/, '#')
        .replace(/[0-9]/, '#')
        .replace(/[0-9]/g, '0');
      // Negative seperate because of the different ways to format negative numbers
      formatNegative = formatter({ value: -((1000 + Math.PI) / percent) })
        .replace(/[0-9]/, '#')
        .replace(/[0-9]/, '#')
        .replace(/[0-9]/, '#')
        .replace(/[0-9]/g, '0');
      const excelID = ('format_' + column.fieldName + column.index).replace(/ /g, '_');
      baseColDef.cellClassRules![excelID] = () => {
        return true;
      };

      const excelStyle: ExcelStyle = {
        id: excelID,
        numberFormat: {
          format: `${formatPositive}; ${formatNegative}`,
        },
      };
      /* escape letter charachters for excel currency format. */
      excelStyle.numberFormat!.format = excelStyle
        .numberFormat!.format.split('')
        .map((char) => {
          // Make sure to escape all characters that are not numbers or special characters
          // If the character is not needed for the format it will be escaped
          return char.match(/[^0-9#%.,;$-]/g) ? `\\${char}` : char;
        })
        .join('');
      gridOptions.excelStyles?.push(excelStyle);
    }
    if (doesColumnDisplayBarChart(config, column, columns)) {
      // Keep the old valueformatter to make sure the formatted value can be displayed in the cell
      const oldFormatter = baseColDef.valueFormatter;

      baseColDef = {
        ...baseColDef,
        // Override the default valueFormatter to get the min and max values for the barchart
        valueFormatter: (params) => {
          if (params.value === undefined || params.value === null) return '';

          const value = params.value;
          return oldFormatter && typeof oldFormatter === 'function' ? oldFormatter(params) : value;
        },
        cellRendererParams: {
          ...baseColDef.cellRendererParams,
          config: config.color,
        },
        cellRenderer: BarchartCellRenderer,
      };

      if (!isCalculation(column)) {
        return {
          ...baseColDef,
          enableValue,
        };
      }
    }
    if (isCalculation(column)) {
      let calc = compileCalculation(column, columns);
      if (!calc) {
        return baseColDef;
      }

      let base = baseColDef;

      return {
        ...base,

        valueGetter: ({ data, node, column: col, api }) => {
          // if row is pinned, return the value of the field
          if (node?.isRowPinned()) return data[field];
          if (col.getAggFunc() !== 'Calc') {
            if (node?.aggData && node?.aggData[field]) return node.aggData[field];
            if (!node?.group) return calc(data);
          }

          // default calc for non group/pivot.
          if (!node?.group) {
            const calculation = calc(data);
            return {
              ...data,
              value: calculation,
              valueOf: () => calculation,
              toString: () => (calculation ? calculation.toString() : ''),
            };
          }

          // no aggData found? Return.
          if (!node?.aggData) return;

          // default calc for non pivot mode with aggregated data (basic grouping).
          if (!api.isPivotMode()) return calc(node.aggData);

          return undefined; // pivot calcuated in aggFuncs.Calc
        },

        // @note: this is used to label this column for updates
        // in the aggregation stage and make sure it gets updated
        // at all the right times. By having this function return
        // undefined ag-grid will just rerun our valueGetter
        // aggFunc: () => {},
        aggFunc: getAggregation(column, true, config.defaultAggregation ?? 'Calc'),
        defaultAggFunc: config.defaultAggregation || 'Calc',
        allowedAggFuncs: enableValueAggMenu
          ? ['Calc', ...defaultAllowedAggregations.filter((name) => name !== 'CountD')]
          : [config.defaultAggregation ?? 'Calc'],
        enableValue,
      };
    }

    let colIsMeasure = isMeasure(column);
    let colIsDate = isDate(column);

    // Excel formatting for dates
    if (colIsDate) {
      const excelID = ('format_' + column.fieldName + column.index).replace(/ /g, '_');
      baseColDef.cellClassRules![excelID] = () => true;
      gridOptions.excelStyles?.push({
        id: excelID,
        dataType: 'DateTime',
        numberFormat: {
          format: config.format?.date || 'yyyy-MM-DD',
        },
      });
    }

    return {
      ...baseColDef,

      enableRowGroup: !colIsMeasure,
      // we can't enable pivot on dates without specifying a keyCreator
      // to format the values as configured by the user.
      // unfortunately formatting to string that early means we loose the
      // ability to sort grouped/pivoted dates unless formatted as yyyy-MM-DD
      enablePivot: !colIsMeasure,
      //@ts-ignore
      pivotComparator: getPivotHeaderSorter(config, column) ?? 0,
      ...getCellRenderer(config.urlExpression, columns, baseColDef),
    };
  };
}

export const reloadCellRenderers = debounce((params) => {
  // Performance increase by only updating the cell renderers for the column that has changed.
  // Can be done by calling gridOptions.api.getCellRendererInstances({ columns: [...column id's] })
  gridApi?.getCellRendererInstances().forEach((cellRenderer) => {
    if (cellRenderer.refresh) {
      cellRenderer.refresh(params);
    }
  });
}, 50);

export function getAggDataValue(node: IRowNode | undefined | null, field: string) {
  if (!node) {
    return;
  }

  let value = node?.aggData?.[field];

  if (typeof value === 'object') {
    value = getNumericValue(value);
  }

  return value;
}

export function getDataSets(nodes: RowNode[] | RowNode) {
  if (!nodes || nodes === null) return;
  // Always nodes in array so we can foreach
  if (!Array.isArray(nodes)) nodes = [nodes];
  // All found data
  let datasets: any = [];

  // get the data from all the nodes
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (!node) return;
    if (node.data !== undefined) {
      datasets.push(node.data);
    }
    // if the node is a group then get the filtered data from the group
    else if (node.group && node.childrenAfterFilter) {
      datasets = datasets.concat(getDataSets(node.childrenAfterFilter));
    }
  }

  return datasets;
}

export function getValueColumnValue(
  valueColumnName: string | undefined,
  isValueColumnContinuous: boolean,
  params: CellClassParams | ValueFormatterParams
): any {
  if (!valueColumnName) return params.value;

  const { node, api } = params;

  if (!node?.group || (node.group && isValueColumnContinuous)) {
    const value = node ? (api.getCellValue({ rowNode: node, colKey: valueColumnName }) ?? params.value) : params.value;

    const isValuColumnCalculation = valueColumnName.startsWith('calc_'); // if so, value is a object containing the value
    return isValuColumnCalculation ? value?.value || value : value;
  }

  if (params.node?.field === valueColumnName) {
    return params.node.key;
  }
  // TODO: This is for when the calueCoumn is used as the pivot column.
  // it is a bit more challenging to find the right index in the pivot keys.

  // else if (params.api.isPivotMode()) {
  // this is the case where the valueColumn is a pivot column (and not the row group column)
  // const pivotKeys = params.column.colDef.pivotKeys;
  // have to get the right pivotKey that matches the valueColumn
  // return pivotKeys?.length && pivotKeys[1];
  // }

  // descrete data of other columns cannot be aggregated
  return undefined;
}

function getFilter(
  column: Column | Calculation,
  config: ColumnConfig,
  formatter: ValueFormatter | undefined,
  showFilterBar: boolean
) {
  if (!config || ('hideFilter' in config && config.hideFilter)) return {};

  const filterProperties = {
    filter: '',
    floatingFilter: showFilterBar,
    filterValueGetter: createFilterValueGetter(column, config.format),
    filterParams: {},
  };

  let filter = '';
  switch (column.dataType) {
    case 'date':
    case 'date-time':
    case 'datetime':
      filter = 'agDateColumnFilter';
      break;
    case 'float':
    case 'int':
      filter = 'agNumberColumnFilter';
      break;
    case 'bool':
      filter = 'agSetColumnFilter';
      break;
    case 'spatial': // @todo: does this need special handling?
    case 'string':
    default:
      if (config.showTextFilter === false || config.showTextFilter === 'list') {
        filter = 'agSetColumnFilter';
      } else {
        filter = 'agTextColumnFilter';
      }
      break;
  }

  if (config.showTextFilter === 'both' && filter !== 'agSetColumnFilter') {
    filterProperties.filter = 'agMultiColumnFilter';
    filterProperties.filterParams = {
      filters: [
        {
          filter,
          filterParams: {
            valueFormatter: formatter,
            keyCreator: formatter,
            inRangeInclusive: true,
            maxNumConditions: 20,
            buttons: ['reset', 'clear'],
          },
        },
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: formatter,
            keyCreator: formatter,
            buttons: ['reset', 'clear'],
          },
        },
      ],
    };
  } else {
    filterProperties.filter = filter;
    if (column.dataType === 'date' || ['datetime', 'date-time'].includes(column.dataType)) {
      filterProperties.filterParams = {
        valueFormatter: formatter,
        keyCreator: formatter,
        inRangeInclusive: true,
        maxNumConditions: 20,
        buttons: ['reset', 'clear'],
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const cellDate = new Date(cellValue);
          const filterDate = new Date(filterLocalDateAtMidnight);
          cellDate.setHours(0, 0, 0, 0);
          filterDate.setHours(0, 0, 0, 0);
          if (cellDate.getTime() === filterDate.getTime()) {
            return 0;
          } else if (cellDate < filterDate) {
            return -1;
          } else {
            return 1;
          }
        },
      };
    } else {
      filterProperties.filterParams = {
        valueFormatter: formatter,
        keyCreator: formatter,
        inRangeInclusive: true,
        maxNumConditions: 20,
        buttons: ['reset', 'clear'],
      };
    }
  }

  return filterProperties;
}

/**
 * @todo: this should use field role
 * There are six pre-defined cell data types: 'text', 'number', 'boolean', 'date', 'dateString' and 'object'.
 * https://www.ag-grid.com/javascript-data-grid/cell-data-types/
 */

type ValueGetter = (params: ValueGetterParams) => any;

function createFilterValueGetter(column: Column | Calculation, config: FormatConfig = {}): ValueGetter | undefined {
  // make ts happy
  // getValue can be passed the Column directly but it is typed to take
  // the column id as a string so w/e...
  // https://github.com/ag-grid/ag-grid/pull/2864

  switch (column.dataType) {
    case 'string': {
      let formatter = createStringFormatter(config.nullReplaceString);
      return (params) => {
        let value = params.getValue(params.column.getId());
        return formatter({ ...params, value });
      };
    }
    case 'int': {
      let fractionDigits = getFractionDigits(column, config);
      let fractionSize = 10 ** fractionDigits;
      return (params) => {
        if (!params.node) {
          return null;
        }

        let value = params.api.getCellValue({ colKey: params.column.getId(), rowNode: params.node });
        if (value === '%null%') {
          return null;
        }
        if (config.style === 'percent') value *= 100;
        if (config.style === 'time') value /= 1000;
        let rounded = Math.round(value * fractionSize) / fractionSize;
        return rounded;
      };
    }
    default: {
      return undefined;
    }
  }
}

function createComparator(column: Column | Calculation, settings: Settings): undefined | ((...args: any[]) => number) {
  if (column.dataType === 'date') {
    return (a: any, b: any, aa: any) => {
      let dateValueA = new Date(a);
      let dateValueB = new Date(b);

      const columnConfig = (!!aa && settings.columnConfig?.[aa.field]) || settings.columnConfig?.[column.fieldName];
      //todo: verify but Should be the same as previous condition
      if (columnConfig && columnConfig.format && columnConfig.format.date) {
        let dateFormat = columnConfig.format?.date || 'DD-MM-yyyy';
        // We need to replace all Quaters with the month because dayjs does not support Quaters
        dateFormat = dateFormat.replace(/Q/g, 'M');
        dateValueA = dayjs(a, dateFormat).toDate();
        dateValueB = dayjs(b, dateFormat).toDate();
      }

      if (isValidDate(dateValueA) && isValidDate(dateValueB)) {
        a = dateValueA;
        b = dateValueB;
      }

      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    };
  } else {
    return (a: any, b: any, nodeA, nodeB) => {
      if (nodeA?.group || nodeB?.group) {
        // certain aggFuncs (avg, count) contain an object with underlyingValues
        a = a?.value ?? a;
        b = b?.value ?? b;
      }

      if (a === '%null%' || isNaN(a)) {
        a = -Infinity;
      }
      if (b === '%null%' || isNaN(b)) {
        b = -Infinity;
      }
      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    };
  }
}

function getAggregation(
  column: Column | Calculation,
  showAggregation: boolean,
  defaultAggregation: boolean | string
): string | null | undefined {
  //ToDo: Check with sri
  // can only assume sum, no good way to get the actual
  // aggregation from the datatable column...
  switch (column.dataType) {
    case 'int':
    case 'string':
    case 'float':
      return showAggregation ? defaultAggregation?.toString() : undefined;
  }
}

function getCellRenderer(urlExpression: string | undefined, columns: Array<Column | Calculation>, baseColDef: ColDef) {
  if (!urlExpression) return;

  return {
    cellRenderer: linkCellRenderer,
    cellRendererParams: {
      ...baseColDef.cellRendererParams,
      url: createTextInterpolator(urlExpression, columns),
    },
  };
}
//to-do: more descript return type

function doesColumnDisplayBarChart(
  config: ColumnConfig,
  column: Calculation | Column,
  columns: Array<Column | Calculation>
) {
  //Not sure if this should take calculation aswell
  // barChart only applies if
  // 1. config.color.barChart is true
  // 2. valueColumn is a measure
  // 3. colorCellBackground is not false (either true or undefined)

  if (
    config &&
    config.color &&
    'barChart' in config.color &&
    config.color.barChart &&
    config?.colorCellBackground !== false
  ) {
    const valueColumnName = config.color?.valueColumn || column.fieldName;

    const valueColumn = columns.find(
      (col) =>
        removeAgg(col.fieldName) === valueColumnName ||
        col.fieldName === valueColumnName ||
        col.index.toString() === valueColumnName
    );

    if (valueColumn) {
      const isValueColumnMeasure = isMeasure(valueColumn);
      return isValueColumnMeasure;
    }
  }
  return false;
}
