import { ColorConfig, ColumnConfig } from '@/types/settings';
import { ValueFormatterParams, GridApi, IRowNode, CellStyle, CellStyleFunc } from 'ag-grid-community';
import { interpolateHsl } from 'd3-interpolate';
import { unlerp } from '../cellRenderers/barChartCellRenderer';
import { getValueColumnValue } from '.';
import { Column } from '@tableau/extensions-api-types';
import { isMeasure, isValueEmpty } from './utils';
import { Calculation } from '@/types/mapping';
import { gridApi } from '..';
import { Settings } from '@/settings';

type Styler = (params: ValueFormatterParams) => CSSProperties | undefined;

export type CSSProperties = {
  [cssProperty: string]: string | undefined;
};

export function createCellStyler(
  column: Column | Calculation,
  colorConfig: ColorConfig,
  conditionalColorBackground: boolean,
  config: ColumnConfig,
  settings: Settings
): Styler {
  const fallBackground = colorConfig?.backgroundColor ? { backgroundColor: colorConfig.backgroundColor } : undefined;
  const fontColor = colorConfig?.fontColor ? { color: colorConfig.fontColor } : undefined;
  const alignment =
    config?.alignment || config?.alignment === '-1' ? config?.alignment : isMeasure(column) ? 'right' : 'left';
  const verticalAlignment =
    settings.autoRowHeight === true ? 'flex-start' : (settings.verticalAlignment ?? 'flex-start');

  let base: CellStyle = {
    ...fallBackground,
    ...fontColor,
    height: '100%',
    display: 'flex',
    alignItems: verticalAlignment === 'Bottom' ? 'flex-end' : verticalAlignment === 'Center' ? 'center' : 'flex-start',
    justifyContent: alignment === 'right' ? 'flex-end' : alignment === 'center' ? 'center' : 'flex-start',
  };
  switch (column.dataType) {
    case 'int':
    case 'float':
      base = { ...base, textAlign: 'right' };
  }

  return createBackgroundStyler(colorConfig, base, conditionalColorBackground, false);
}

export function createBackgroundStyler(
  config: ColorConfig,
  base: CellStyle,
  conditionalColorBackground: boolean = true,
  headerCell = false
): Styler {
  if (!config) {
    return (params) =>
      // if there isn't a value then don't return a style (like background color)
      isValueEmpty(params.value) ? {} : base;
  }

  const valueColumnName = config?.valueColumn;

  switch (config?.type) {
    // @todo: ColorConfig type is not present in the configuration created by
    // older versions of SuperTables. We need to make sure to default to it.
    default:
    case 'continuous': {
      let { belowColor = '#e15759', inColor = '#59a14f', aboveColor = '#e15759', gradient = false } = config;
      // ensure either bound is not an empty string as that will
      // evaluate to 0 when compared to a number...
      let lowerBound =
        config.lowerBound === undefined || config.lowerBound === '' ? -Infinity : Number(config.lowerBound);

      let upperBound =
        config.upperBound === undefined || config.upperBound === '' ? Infinity : Number(config.upperBound);

      // HSL interpolation gives much nicer results than rgb
      let colorLerp = interpolateHsl(belowColor, aboveColor);

      return (params) => {
        // if barChart is true and the settings is for background color and not font color
        if (params.node?.level !== -1 && config.barChart && conditionalColorBackground !== false) return base;

        let value = getValueColumnValue(valueColumnName, true, params);

        if (isValueEmpty(value)) {
          //if in pivot mode and no value is found, return the background color
          if (params.node?.group && gridApi?.isPivotMode()) {
            value = params.value;
          } else {
            return base;
          }
        }
        if (!config.lowerBound && !config.upperBound) return base;
        let backgroundColor = inColor;
        if (value < lowerBound) {
          backgroundColor = belowColor;
        }
        if (value > upperBound) {
          backgroundColor = aboveColor;
        }

        if (gradient && config.lowerBound && config.upperBound) {
          const rgbBackgroundColor = colorLerp(unlerp(lowerBound, upperBound, value));
          backgroundColor = rgbStringToHex(rgbBackgroundColor);
        }

        if (conditionalColorBackground) {
          let fontColor = config.useCustomFontColor ? config.customFontColor : config.fontColor;
          if (!fontColor) {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(backgroundColor) || [];
            const backgroundColorArr = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
            let color = Math.round(
              (backgroundColorArr[0] * 299 + backgroundColorArr[1] * 587 + backgroundColorArr[2] * 114) / 1000
            );
            fontColor = color > 125 ? '#666666' : '#ffffff';
          }
          // if value is null or infinity then use the cell background color
          if (isValueEmpty(value) || (typeof value === 'object' && value !== null && isValueEmpty(value.value))) {
            return base;
          } else {
            return {
              ...base,
              backgroundColor,
              color: fontColor,
            };
          }
        } else {
          return { ...base, color: backgroundColor };
        }
      };
    }

    case 'discrete':
      function valueGetter(params: ValueFormatterParams<any, any>) {
        let valueColumn = config?.valueColumn
          ? config?.valueColumn
          : params?.column
            ? params?.column?.getColId()
            : Object.keys(params.data).find((key) => params.data[key] === params.value);
        if (valueColumn) {
          if (params.node?.group) {
            let rowIndex = params.node.rowIndex;
            if (!rowIndex) return;
            let requiredRow = params.api.getDisplayedRowAtIndex(rowIndex);
            if (!requiredRow) return;
            let found = false;
            while (!found && requiredRow) {
              if (requiredRow?.field && requiredRow?.field === valueColumn) {
                found = true;
                return requiredRow.key;
              } else {
                requiredRow = requiredRow.parent ?? undefined;
              }
            }
          } else {
            const getVal = (api: GridApi, node: IRowNode) => api.getCellValue({ rowNode: node, colKey: valueColumn });
            return params.node ? getVal(params.api, params.node) : undefined;
          }
        } else {
          // we can't find a column
        }
      }
      //@todo: Clean up a bit
      return (params) => {
        let value = params.value ? valueGetter(params) : undefined;
        if (isValueEmpty(value)) {
          if (headerCell) return base;
          return {};
        }
        // find the value from sub-category column in the current row
        let backgroundColor =
          config?.map && config.map.hasOwnProperty(String(value)) ? config.map[String(value)] : undefined;
        let fontColor = config.useCustomFontColor ? config.customFontColor : config.fontColor;

        if (backgroundColor) {
          if (!fontColor) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(backgroundColor) || [];
            let backgroundColorArr = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
            var color = Math.round(
              (backgroundColorArr[0] * 299 + backgroundColorArr[1] * 587 + backgroundColorArr[2] * 114) / 1000
            );
            fontColor = color > 125 ? '#666666' : '#ffffff';
          }
        } else {
          backgroundColor = base.backgroundColor as string;
        }
        if (conditionalColorBackground) {
          return { ...base, backgroundColor, color: fontColor ?? 'inherit' };
        } else {
          return { ...base, color: backgroundColor };
        }
      };
  }
}

function rgbStringToHex(rgbString: string) {
  const rgbValues = rgbString.match(/\d+/g)?.map(Number);

  if (!rgbValues) {
    return '#000000'; //returns a default hex if not match find
  }

  const hex = rgbValues
    .map((val) => {
      let hex = val.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('');
  return '#' + hex;
}
