// @ts-check
import { Calculation, ValueFormatter } from '@/types/mapping';
import { ColumnConfig, FormatConfig, NegativeValueFormat, PositiveValueFormat } from '@/types/settings';
import { Column } from '@tableau/extensions-api-types';
import { ValueFormatterParams } from 'ag-grid-community';
import formatDate from '@/utils/dateFormat';
import { isValid as isValidDate } from 'date-fns';
import { Time, defaultTimeRange } from '../utils/time';
import { getFractionDigits } from './mapping/utils';

const BLANK = '';

type Formatter<Type> = (params: ValueFormatterParams<Type> | { value: number }) => string;
/**
 * creates a function that formats a string value based on
 * a format. This solves nulls being a %null% string.
 */
export function createStringFormatter(nullReplaceString: string = ''): Formatter<string | number | Date> {
  return function (params) {
    //we should fix it later this is a hack
    if (
      params.value === '%null%' ||
      params.value === 'Null' ||
      params.value === undefined ||
      typeof params.value === 'object'
    ) {
      return nullReplaceString ?? BLANK;
    }
    // we want to see the values of the footer in csv exports
    const isCSV = 'type' in params && params.type === 'csv';
    if ('node' in params && params.node?.footer && typeof params.value === 'string' && !isCSV)
      return nullReplaceString ?? BLANK;
    return params.value;
  };
}

/**
 * getDateFormatter creates a function that formats a date value based on
 * a format string. Numbers are treated as milliseconds.
 */
export function createDateFormatter(format: string): Formatter<string | number | Date> {
  // Note: if we ever want to update to date-fns version 2 we are in trouble...
  // They opted to use the Unicode Technical Standard #35
  // https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  // Since this is significantly different than the format string used in
  // version 1 we need to detect whether it is an old format string or not
  // and "upgrade" it to the correct standard.
  return function (params) {
    let date = params.value;

    if (date === null || date === undefined) {
      return BLANK;
    }

    if (typeof date === 'number') {
      date = dateFromMillis(date);
    }
    // if there is no data we get the already formatted date so we don't need to reformat
    // @ts-ignore
    if (
      typeof params.data === 'undefined' &&
      params.type !== 'csv' &&
      params.type !== 'pdf' &&
      params.type !== 'tooltip'
    ) {
      return formatDate(date, format);
    }

    if (typeof date === 'string') {
      // We can be passed a ToString-ed Date by ag-grid
      // When grouping ag-grid creates a key by ToString-ing the value and then
      // passes that to the value formatter ¯\_(ツ)_/¯
      date = new Date(date);
    }

    if (isValidDate(date) === false) {
      return BLANK;
    }

    return formatDate(date, format);
  };
}

function dateFromMillis(millis: number): Date {
  return new Date(0, 0, 1, 0, 0, 0, millis);
}

/**
 * The configuration for each time format part.
 * size is the amount of milliseconds the timePart consists of.
 * divider is the character to be printed after the timePart.
 * minimumLength is the minimum number of characters for that timePart, when
 * shorted it will be padded with '0's from the start.
 */
const timeParts = {
  [Time.DAYS]: {
    size: 86400000,
    divider: ' ',
    minimumLength: 1,
  },
  [Time.HOURS]: {
    size: 3600000,
    divider: ':',
    minimumLength: 2,
  },
  [Time.MINUTES]: {
    size: 60000,
    divider: ':',
    minimumLength: 2,
  },
  [Time.SECONDS]: {
    size: 1000,
    divider: '.',
    minimumLength: 2,
  },
  [Time.MILLIS]: {
    size: 1,
    divider: '',
    minimumLength: 3,
  },
};

/**
 * getTimeFormatter creates a function that formats a millisecond value
 * based on the number the given format sizes.
 * Given the format `[Time.HOURS, Time.MILLIS]` this function will format
 * 259200000 to `72:00:00.000` the same value formatted with
 * `[Time.DAYS, Time.DAYS]` looks like `3`.
 */
export function createTimeFormatter(timeRange = defaultTimeRange): Formatter<number> {
  return function (params) {
    let value = getNumericValue(params);

    if (Number.isFinite(value) === false) {
      return BLANK;
    }

    let result = '';
    let remainder = value;
    // we will start at the largest format (which is first in timeRange)
    // and loop until we hit the smallest bucket size.
    for (let partIndex = timeRange[0]; partIndex <= timeRange[1]; partIndex++) {
      let part = timeParts[partIndex];
      // calculate the amount of times the remainder fits in this timePart
      let current = Math.floor(remainder / part.size);
      // append the timePart to result (padded to the min length)
      result += padStart(part.minimumLength, '0', current);
      if (partIndex !== timeRange[1]) {
        // add the divider and calculate the new remainder if
        // this was not on the last timePart
        result += part.divider;
        remainder = remainder % part.size;
      }
    }

    return result;
  };
}

/**
 * hacky String.prototype.padStart implementation
 */
function padStart(desiredLength: number, insertValue: string, value: any) {
  let result = String(value);
  while (result.length < desiredLength) {
    result = insertValue + result;
  }
  return result;
}

type NumberFormatterConfig = {
  prefix?: string;
  suffix?: string;
  negativeValueFormat?: NegativeValueFormat;
  positiveValueFormat?: PositiveValueFormat;
  nullReplaceString?: string;
};

export type NumberFormatterTruncater = {
  truncateValues?: boolean;
  truncateMinValue: string | null;
  truncateMaxValue: string | null;
};

export type Seperator = {
  k?: string;
  m?: string;
  b?: string;
  t?: string;
};
/**
 * getNumberFormatter creates a function that formats a numeric value based on
 * the number format options of Number.toLocaleString.
 */
export function createNumberFormatter(
  options: Intl.NumberFormatOptions & { separators?: Seperator },
  { prefix = '', suffix = '', negativeValueFormat, positiveValueFormat, nullReplaceString }: NumberFormatterConfig,
  truncater: NumberFormatterTruncater,
  isExport: boolean = false
): Formatter<number> {
  const formatter = Intl.NumberFormat(isExport ? 'en-IN' : undefined, options);

  const separators = options?.separators;
  const isSeparatorsDefined = separators && Object.values(separators)?.some(Boolean);

  const formatSeparator = (value: number): string => {
    if (isSeparatorsDefined) {
      const thresholds: { limit: number; divisor: number; suffix: string | undefined }[] = [
        { limit: 1e12, divisor: 1e12, suffix: separators.t },
        { limit: 1e9, divisor: 1e9, suffix: separators.b },
        { limit: 1e6, divisor: 1e6, suffix: separators.m },
        { limit: 1e3, divisor: 1e3, suffix: separators.k },
      ];

      for (let i = 0; i < thresholds.length; i++) {
        const { limit, divisor, suffix } = thresholds[i];
        if (suffix && Math.abs(value) >= limit) {
          return formatter.format(Math.abs(value) / divisor) + ' ' + suffix;
        }
      }
    }

    return formatter.format(Math.abs(value));
  };

  const formatValue = (value: number, format?: string, positivePrefix = '') => {
    const formattedValue = isSeparatorsDefined ? formatSeparator(value) : formatter.format(Math.abs(value));
    switch (format) {
      case 'minusparentheses':
        return `(-${prefix}${formattedValue}${suffix})`;
      case 'parentheses':
        return `(${prefix}${formattedValue}${suffix})`;
      case 'minusright':
        return `${prefix}${formattedValue}${suffix}-`;
      case 'minus':
        return `-${prefix}${formattedValue}${suffix}`;
      case 'none':
        return `${prefix}${formattedValue}${suffix}`;
      case 'plusparentheses':
        return `(+${prefix}${formattedValue}${suffix})`;
      case 'plusright':
        return `${prefix}${formattedValue}${suffix}+`;
      case 'includeplus':
        return `${positivePrefix}${prefix}${formattedValue}${suffix}`;
      default:
        return `${prefix}${formattedValue}${suffix}`;
    }
  };

  return function (params) {
    let value = getNumericValue(params);

    if (truncater.truncateValues) {
      if (truncater.truncateMinValue != null && Number(truncater.truncateMinValue) >= value) {
        value = Number(truncater.truncateMinValue);
      }
      if (truncater.truncateMaxValue != null && Number(truncater.truncateMaxValue) <= value) {
        value = Number(truncater.truncateMaxValue);
      }
    }

    if (!Number.isFinite(value)) {
      return nullReplaceString ?? BLANK;
    }

    const positivePrefix = positiveValueFormat === 'includeplus' ? '+' : '';

    if (value < 0) {
      return formatValue(value, negativeValueFormat ?? 'minus');
    } else {
      return formatValue(value, positiveValueFormat, positivePrefix);
    }
  };
}

//Does not seem like its used anywhere
export function kmbSeparatorFormatter(value: string) {
  //write the kmb separator formatter here
  return value;
}

/**
 * Helper function to get numeric values from ValueFormatterParams
 */
export function getNumericValue({ value }: ValueFormatterParams<number> | { value: number }) {
  if (value) {
    // Some (avg and count) aggregation functions return an object
    // In order to get a number we need to call their toNumber method
    if (typeof value.toNumber === 'function') {
      value = value.toNumber();
    } else if (typeof value.valueOf === 'function') {
      // pivot cells return an object for calculations
      value = value.valueOf();
    } else if (!!value.value) {
      // value is a property of value
      value = value.value;
    }
  }
  return value;
}

export function createFormatter(
  column: Column | Calculation,
  configStyle: FormatConfig = {},
  config: ColumnConfig
): ValueFormatter | undefined {
  switch (column.dataType) {
    case 'string': {
      return createStringFormatter(configStyle.nullReplaceString);
    }
    case 'date': {
      return createDateFormatter(configStyle.date || 'yyyy-MM-dd');
    }
    case 'datetime':
    case 'date-time': {
      return createDateFormatter(configStyle.date || 'yyyy-MM-dd HH:mm');
    }

    case 'float':
    case 'number':
    case 'int': {
      let style = configStyle.style ? configStyle.style : 'decimal';
      if (style === 'time') {
        return createTimeFormatter(configStyle.timeRange);
      }
      /**
       * @todo: Could be cleaned up later
       */
      let fractionDigits = getFractionDigits(column, configStyle);
      let seperators: Seperator = {
        k: configStyle.kSeparator,
        m: configStyle.mSeparator,
        b: configStyle.bSeparator,
        t: configStyle.tSeparator,
      };

      let formatOptions = {
        style,
        // currency property needs to be set if style is currency
        // defaulting to USD is kind of strange though...
        currency: configStyle.style === 'currency' ? configStyle.currency || 'USD' : 'USD',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
        useGrouping: configStyle.useGrouping,
        separators: seperators,
      };

      let truncater: NumberFormatterTruncater = {
        truncateValues: config?.turncator?.truncateValues || false,
        truncateMinValue: config?.turncator?.truncateMinValue || null,
        truncateMaxValue: config?.turncator?.truncateMaxValue || null,
      };

      let formatter = createNumberFormatter(formatOptions, configStyle, truncater);

      // we need a formatter with useGrouping = false for exporting because
      // Excel doesn't play nice with (thousand) seperators
      let exportOptions = { ...formatOptions, useGrouping: false };
      // @ts-ignore: HACK so we can access this in formatWithValueFormatter
      formatter.exportFormatter = createNumberFormatter(exportOptions, configStyle, truncater);

      return formatter;
    }
  }
}
