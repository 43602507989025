import { Button } from '@/components/ui/button';
import { SamlSettings } from '@/types/writeback';
import Spinner from '@/components/spinner';
import { onDesktop } from '@/utils/tableau';

interface SamlProps {
  samlSettings: SamlSettings[];
  startAuthentication: (key: string, isOauth: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const Saml = ({ samlSettings, startAuthentication, loading, setLoading }: SamlProps) => {
  const samlSetting = samlSettings?.[0];

  const handleAuthentication = () => {
    setLoading(true);
    if (samlSetting) {
      startAuthentication(samlSetting.key, false);
    }
  };

  return loading ? (
    <div className="mt-5 flex flex-col items-center justify-center">
      <p>Currently there is a process running in the browser.</p>
      {!onDesktop() && <div>Be sure the browser does not block popups.</div>}
      <Spinner className="mt-5" />
    </div>
  ) : (
    <div className="mt-5 flex flex-col gap-2">
      <Button
        key={samlSetting?.key}
        className="cursor-pointer border p-1"
        onClick={handleAuthentication}>
        {samlSetting?.name}
      </Button>
    </div>
  );
};

export default Saml;
